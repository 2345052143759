import React, { useState } from 'react';

interface ButtonComponentProps {
  label: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  dataCy?: string;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  label,
  onClick,
  disabled = false,
  style = {},
  type = 'button',
  dataCy,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      data-cy={dataCy}
      type={type}
      className="button"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#FFFFFF',
        backgroundColor: disabled
          ? '#00BDD6'  
          : isHovered
          ? '#00A9C0FF'
          : '#00BDD6', 
          border: 'none',
        borderRadius: '4px',
        opacity: disabled ? 0.4 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
    >
      {label}
    </button>
  );
};

export default ButtonComponent;
