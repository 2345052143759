import React, { useEffect, useRef, useState } from 'react';
import Footer from '../components/Footer';
import ButtonComponent from '../components/ButtonComponent';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import bookImage from '../assets/book.png';

const Home: React.FC = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(window.matchMedia("(max-width: 820px)").matches);
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 550px)").matches);

  const navigator = useNavigate();

  const mainRef = useRef<HTMLDivElement>(null);
  const escalaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScreenResize = () => {
      setIsMediumScreen(window.matchMedia("(max-width: 820px)").matches);
      setIsSmallScreen(window.matchMedia("(max-width: 550px)").matches);
    };

    const mediaQueryMedium = window.matchMedia("(max-width: 820px)");
    const mediaQuerySmall = window.matchMedia("(max-width: 550px)");

    mediaQueryMedium.addEventListener('change', handleScreenResize);
    mediaQuerySmall.addEventListener('change', handleScreenResize);
    return () => {
      mediaQueryMedium.removeEventListener('change', handleScreenResize);
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
    };
  }, []);

  const handleRegisterRedirect = () => {
    navigator('/register');
  };

  const handleLoginRedirect = () => {
    navigator('/login');
  };

  const handleScroll = () => {
    if (escalaRef.current) {
      escalaRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start', 
      });
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}>
      
      <div style={{
        backgroundColor: 'rgba(255, 255, 255)',
        fontFamily: 'Inter, sans-serif',
        fontSize: '0.8em',
        fontWeight: 400,
        lineHeight: '26px',
        color: '#565E6CFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '10%',
        paddingTop: '1em',
        position: 'relative',
      }}>
        <div style={{
            position: 'absolute',
            left: '1em',
            padding: '0.5em',
            fontSize: '1.5em',
            fontWeight: 800,
            display: 'flex',
          }}
        >
          <img
              src={logo}
              alt="Logo"
              style={{
                width: '14em',
                height: '4.75em',
              }}
            />
        </div>

        <div 
          onClick={handleScroll} 
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleScroll();
          }}
          style={{
            cursor: 'pointer',
            position: 'relative',
            left:-20,
          }}
        >
            Sobre EMIA
        </div>

        <ButtonComponent
          label="Login"
          disabled={false}
          style={{
            position: 'absolute', 
            right: '3em', 
            width: (isMediumScreen || isSmallScreen) ? '50px' : '100px', 
            height: '30px', 
            justifyContent: 'center',
          }}
          onClick={handleLoginRedirect}
          type="button" 
        />
      </div>
      

      <main ref={mainRef} style={{
        backgroundColor: 'rgba(255, 255, 255)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1, 
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: '1em',
        paddingBottom: '1em',
      }}>
        
       
        <div 
          id='welcome'
          style={{
            backgroundColor: 'rgba(235, 253, 255)',
            display: 'flex',
            flexDirection: (isMediumScreen || isSmallScreen) ? 'column' : 'row',
            width: '100%', 
            minHeight: '60vh', 
            alignItems: 'center',
            padding: '1em 0',
          }}
        >
          <div style={{
            width: (isMediumScreen || isSmallScreen) ? '100%' : '55%',
            order: (isMediumScreen || isSmallScreen) ? 1 : 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: (isMediumScreen || isSmallScreen) ? '1em' : '0',
          }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: (isMediumScreen || isSmallScreen) ? '50%' : '80%',
                height: 'auto',
                position:'relative',
                top: -20
              }}
            />
          </div>

          <div 
            style={{
              width: (isMediumScreen || isSmallScreen) ? '100%' : '45%',
              order: (isMediumScreen || isSmallScreen) ? 2 : 1, 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1em',
              gap: '1em',
            }}
          >
            <div
              style={{
                fontWeight: 700,
                color: '#323842FF',
                fontFamily: 'Epilogue',
                width: '100%',
                fontSize: '2em',
                textAlign: 'center',
              }}
            
            >
              Seja muito bem-vindo(a) ao aplicativo da Escala Motora Infantil de Alberta (EMIA).
            </div>
            <div style={{textAlign: 'center', width: '100%', fontFamily: 'Inter', color: '#323842FF', fontWeight: 400, fontSize: '1em'}}>
              Inscreva-se agora e comece a utilizar o aplicativo da EMIA, é gratuito! 
              Essa é uma iniciativa do Núcleo de Estudos em Neuropediatria e Motricidade (NENEM), 
              coordenado pela Profa. Dra. Eloisa Tudella.
            </div>
            <ButtonComponent
                label="Inscreva-se"
                disabled={false}
                style={{
                  width: (isMediumScreen || isSmallScreen) ? '50%' : '20%', 
                  height: '40px', 
                  justifyContent: 'center',
                }}
                onClick={handleRegisterRedirect}
                type="button"
            />
          </div>
        </div>

        <div 
          ref={escalaRef}
          style={{
            display: 'flex', 
            gap: '0.3em', 
            fontFamily: 'Epilogue', 
            fontSize: '2.5em', 
            fontWeight: 700, 
            lineHeight: '68px',
            marginTop: '1em',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}>
          <div style={{color: '#00BDD6FF'}}>
            Escala
          </div>
          <div>
            Motora
          </div>
          <div style={{color: '#00BDD6FF'}}>
            Infantil
          </div>
          <div>
            de
          </div>
          <div style={{color: '#00BDD6FF'}}>
            Alberta
          </div>
        </div>

        <div style={{gap: '2em', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <div style={{alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex'}}>
            <img
              src={bookImage}
              alt="Livro"
              style={{
                width: (isMediumScreen || isSmallScreen) ? '50%' : '20%',
                height: 'auto',
                marginTop: '2em',
              }}
            />
          </div>

          <div style={{
            width: '80%', 
            margin: '0 auto',
            textAlign: 'left',
            fontFamily: 'Inter', 
            fontSize: '1em', 
            color: '#6E7787FF', 
          }}>
            A EMIA, originalmente chamada de Alberta Infant Motor Scale (AIMS), 
            foi desenvolvida por Piper & Darrah na província de Alberta, no Canadá, 
            e publicada em 2020. Atualmente, o manual encontra-se 
            em sua segunda edição, publicado em 2024 pelas autoras.
          </div>

          <div style={{
            width: '80%', 
            margin: '0 auto', 
            textAlign: 'left', 
            fontFamily: 'Inter', 
            fontSize: '1em', 
            color: '#6E7787FF', 
          }}>
            Foi construída para mensurar o desenvolvimento motor de lactentes desde o nascimento até os 18 meses de idade – ou até a marcha independente. Os principais usos da Escala são para:
            <ul style={{ listStyleType: 'disc', paddingLeft: '1.5em', textAlign: 'left', marginTop: '1em' }}>
              <li>
                Identificar lactentes com atrasos ou desvios no desenvolvimento motor
              </li>
              <li>
                Avaliação do desenvolvimento ou amadurecimento motor ao longo do tempo. 
              </li>
            </ul>
          </div>
          <div style={{
            width: '80%', 
            margin: '0 auto', 
            textAlign: 'left', 
            fontFamily: 'Inter', 
            fontSize: '1em', 
            color: '#6E7787FF', 
            paddingBottom: '2em'
          }}>
            A Escala é composta por 58 itens, subdividos em quatro posturas: prona (21 itens), 
            supina (nove itens), sentada (12 itens), e ortostática (16 itens). 
            Por se tratar de uma escala observacional, a movimentação do lactente deve ser ativa. 
          </div>
        </div>
        
      </main>

      <Footer style={{
        textAlign: 'center',
        backgroundColor: '#f7fafc',
        minHeight: '3%',
        display:'flex',
        justifyContent:'center'
      }} />
    </div>
  );
};

export default Home;
