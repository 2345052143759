import React from 'react';

interface FooterProps {
  style?: React.CSSProperties;
}
const handleRedirectCT = () => {
  window.location.href = 'https://ctdevelopers.solutions/';
}

const Footer: React.FC<FooterProps> = ({ style = {} }) => {
  return (
    <footer 
      style={{
        padding: '0.6em',
        fontFamily: 'Epilogue',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: '#f7fafc', 
        fontSize: '0.7rem',
        ...style,
      }}
    >
      <div>© 2024 Escala Motora Infantil de Alberta. Todos os direitos reservados <div >.</div></div>
      <div onClick={handleRedirectCT} style={{cursor: 'pointer'}}>Desenvolvido por CT Developers.</div>
    </footer>
  );
}

export default Footer;
