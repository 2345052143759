import React, { CSSProperties, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonComponent from './ButtonComponent';
import FormInputComponent from './FormInputComponent';
import { toast } from 'react-toastify';
import { ParseError, AllowedFormsInterfaces } from '../utils/ParseError';
import { RegisterForm } from '../interfaces/RegisterForm';

interface RegisterFormComponentProp {
  style?: CSSProperties;
}

export default function RegisterFormComponent({ style = {} }: RegisterFormComponentProp) {
  // const [isMediumScreen, setIsMediumScreen] = useState(
  //   window.matchMedia('(max-width: 820px)').matches
  // );
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia('(max-width: 550px)').matches
  );
  const navigate = useNavigate();

  useEffect(() => {
    const handleScreenResize = () => {
      // setIsMediumScreen(window.matchMedia('(max-width: 820px)').matches);
      setIsSmallScreen(window.matchMedia('(max-width: 550px)').matches);
    };

    const mediaQueryMedium = window.matchMedia('(max-width: 820px)');
    const mediaQuerySmall = window.matchMedia('(max-width: 550px)');

    mediaQueryMedium.addEventListener('change', handleScreenResize);
    mediaQuerySmall.addEventListener('change', handleScreenResize);

    return () => {
      mediaQueryMedium.removeEventListener('change', handleScreenResize);
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
    };
  }, []);

  const [formData, setFormData] = useState<RegisterForm>({
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    full_name: '',
    profession: '',
    contact_number: '',
    workplace: '',
  });

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFieldErrors({});

    const missingFields = Object.entries(formData)
      .filter(([key, value]) => value.trim() === '')
      .map(([key]) => key);

    if (missingFields.length > 0) {
      const errors: { [key: string]: string } = {};
      missingFields.forEach((field) => {
        errors[field] = 'Campo obrigatório.';
      });
      setFieldErrors(errors);

      toast.error('Todos os campos precisam ser preenchidos.');
      return;
    }

    if (formData.password !== formData.confirm_password) {
      setFieldErrors({
        password: 'As senhas não coincidem.',
        confirm_password: 'As senhas não coincidem.',
      });
      toast.error('As senhas não coincidem.');
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL || 'https://escalaemia.com.br/api';

    fetch(`${apiUrl}/register/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: formData.username,
        email: formData.email,
        password: formData.password,
        full_name: formData.full_name,
        profession: formData.profession,
        contact_number: formData.contact_number,
        workplace: formData.workplace,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new ParseError(errorData, 'RegisterForm' as AllowedFormsInterfaces);
          });
        }
        toast.success('Seu registro foi feito com sucesso!');
        navigate('/verify', { state: { email: formData.email } });
      })
      .catch((error) => {
        if (error instanceof ParseError) {
          const fieldErrors = error.handleError();
          setFieldErrors(fieldErrors);

        
          const firstErrorMessage = Object.values(fieldErrors)[0];
          if (firstErrorMessage) {
            toast.error(firstErrorMessage);
          }
        } else {
          toast.error('Erro ao conectar com o servidor.');
        }
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
        width: '25%',
        margin: '0 auto',
        ...style,
      }}
    >
      <div>
        <div
          style={{
            fontFamily: 'Epilogue',
            color: '#171A1FFF',
            fontWeight: 600,
            fontSize: '1.2em',
            padding: '1.5em 2em 0.2em 1em',
            gap: '0.5em',
            display: 'flex',
            flexDirection:'column'

          }}
        >
          Faça seu cadastro.
        </div>
        <Link
          to="/login"
          style={{
            height: '7%',
            width: '25%',
            fontSize: '0.9em',
            paddingLeft: '1.5em',
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
            cursor: 'pointer', 
            color: '#00A9C0FF',
            textDecoration: 'none',
            fontWeight: 600, 
          }}
        >
          Já tem login? Entre!
        </Link>
      </div>
      
        
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          {!isSmallScreen && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2em',
                width: '100%',
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              <FormInputComponent
                name="username"
                label="Nome do Usuário"
                placeholder="Nome do Usuário"
                disabled={false}
                isPassword={false}
                value={formData.username}
                onChange={handleChange}
                hasError={!!fieldErrors.username}
                errorMessage={fieldErrors.username}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
              <FormInputComponent
                name="full_name"
                label="Nome Completo"
                value={formData.full_name}
                onChange={handleChange}
                placeholder="Nome Completo"
                disabled={false}
                isPassword={false}
                hasError={!!fieldErrors.full_name}
                errorMessage={fieldErrors.full_name}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
            </div>
          )}

          {isSmallScreen && (
            <>
              <FormInputComponent
                name="username"
                label="Nome do Usuário"
                placeholder="Nome do Usuário"
                disabled={false}
                isPassword={false}
                value={formData.username}
                onChange={handleChange}
                hasError={!!fieldErrors.username}
                errorMessage={fieldErrors.username}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
              <FormInputComponent
                name="full_name"
                label="Nome Completo"
                value={formData.full_name}
                onChange={handleChange}
                placeholder="Nome Completo"
                disabled={false}
                isPassword={false}
                hasError={!!fieldErrors.full_name}
                errorMessage={fieldErrors.full_name}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
            </>
          )}

          <FormInputComponent
            name="email"
            label="Email"
            placeholder="Digite seu email"
            disabled={false}
            isPassword={false}
            value={formData.email}
            onChange={handleChange}
            hasError={!!fieldErrors.email}
            errorMessage={fieldErrors.email}
            style={{
              width: '100%',
              flexGrow: 1,
              flexShrink: 1,
            }}
            showErrorMessage={false}
          />

          {!isSmallScreen && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2em',
                width: '100%',
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              <FormInputComponent
                name="password"
                label="Senha"
                placeholder="Digite sua senha"
                disabled={false}
                isPassword={true}
                value={formData.password}
                onChange={handleChange}
                hasError={!!fieldErrors.password}
                errorMessage={fieldErrors.password}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
              <FormInputComponent
                name="confirm_password"
                label="Confirme a Senha"
                placeholder="Confirme sua senha"
                value={formData.confirm_password}
                onChange={handleChange}
                disabled={false}
                isPassword={true}
                hasError={!!fieldErrors.confirm_password}
                errorMessage={fieldErrors.confirm_password}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
            </div>
          )}

          {isSmallScreen && (
            <>
              <FormInputComponent
                name="password"
                label="Senha"
                placeholder="Digite sua senha"
                disabled={false}
                isPassword={true}
                value={formData.password}
                onChange={handleChange}
                hasError={!!fieldErrors.password}
                errorMessage={fieldErrors.password}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
              <FormInputComponent
                name="confirm_password"
                label="Confirme a Senha"
                placeholder="Confirme sua senha"
                value={formData.confirm_password}
                onChange={handleChange}
                disabled={false}
                isPassword={true}
                hasError={!!fieldErrors.confirm_password}
                errorMessage={fieldErrors.confirm_password}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
            </>
          )}

          {!isSmallScreen && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2em',
                width: '100%',
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              <FormInputComponent
                name="contact_number"
                label="Telefone para Contato"
                placeholder="Telefone para Contato"
                disabled={false}
                isPassword={false}
                value={formData.contact_number}
                onChange={handleChange}
                hasError={!!fieldErrors.contact_number}
                errorMessage={fieldErrors.contact_number}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
              <FormInputComponent
                name="workplace"
                label="Local de Trabalho"
                placeholder="Local de Trabalho"
                value={formData.workplace}
                onChange={handleChange}
                disabled={false}
                isPassword={false}
                hasError={!!fieldErrors.workplace}
                errorMessage={fieldErrors.workplace}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
            </div>
          )}

          {isSmallScreen && (
            <>
              <FormInputComponent
                name="contact_number"
                label="Telefone para Contato"
                placeholder="Telefone para Contato"
                disabled={false}
                isPassword={false}
                value={formData.contact_number}
                onChange={handleChange}
                hasError={!!fieldErrors.contact_number}
                errorMessage={fieldErrors.contact_number}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
              <FormInputComponent
                name="workplace"
                label="Local de Trabalho"
                placeholder="Local de Trabalho"
                value={formData.workplace}
                onChange={handleChange}
                disabled={false}
                isPassword={false}
                hasError={!!fieldErrors.workplace}
                errorMessage={fieldErrors.workplace}
                style={{
                  width: '100%',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                showErrorMessage={false}
              />
            </>
          )}

          <FormInputComponent
            name="profession"
            label="Profissão"
            placeholder="Profissão"
            value={formData.profession}
            onChange={handleChange}
            disabled={false}
            isPassword={false}
            hasError={!!fieldErrors.profession}
            errorMessage={fieldErrors.profession}
            style={{
              width: '100%',
              flexGrow: 1,
              flexShrink: 1,
            }}
            showErrorMessage={false}
          />

          <div style={{ paddingTop: '2em' }}>
            <ButtonComponent
              label="Cadastrar"
              disabled={false}
              style={{
                width: '100%',
                height: '30px',
                justifyContent: 'center',
                flexGrow: 1,
                flexShrink: 1,
              }}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
