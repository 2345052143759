import React, { ChangeEvent, useState } from 'react';
import hide from '../assets/hide.png';
import view from '../assets/view.png';

interface FormInputComponentProps {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  isPassword?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  hasError?: boolean;
  style?: React.CSSProperties;
  errorMessage?: string;
  showErrorMessage?: boolean;
  variant?: 'register' | 'login' | 'general';
  inputType?: 'text' | 'numeric' | 'password';
  dataCy?: string;
}

const FormInputStyles = {
  container: {
    display: 'flex',
    opacity: 1,
    flexDirection: 'column' as const,
    flexGrow: 1,
    gap: '0.5em',
    padding: '0.5em',
  },
  label: {
    fontFamily: 'Epilogue',
    fontSize: '1em',
    lineHeight: '2em',
    fontWeight: 700,
    whiteSpace: 'nowrap' as const,
  },
  inputBase: {
    fontFamily: 'Inter',
    fontWeight: 400,
    outline: 'none',
  },
  registerInput: {
    width: '100%',
    minHeight: '35px',
    display: 'flex',
    textAlign: 'left' as const,
    paddingLeft: '1em',
    fontSize: '0.8em',
    backgroundColor: '#F3F4F6',
    borderRadius: '4px',
    borderWidth: '0px',
    borderColor: 'transparent',
    transition: 'background 0.3s ease',
    boxShadow: 'none',
  },
  loginInput: {
    width: '100%',
    color: '#9095A0FF',
    background: 'transparent',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderWidth: '0px',
    boxShadow: '0 1px 0 #bcc1ca',
    fontSize: '14px',
    lineHeight: '22px',
    minHeight: '35px',
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.8em',
    fontFamily: 'Inter',
    minHeight: '1em',
  },
};

const FormInputComponent: React.FC<FormInputComponentProps> = ({
  name,
  label,
  isPassword = false,
  placeholder = '',
  disabled = false,
  style = {},
  value,
  onChange,
  hasError = false,
  errorMessage = '',
  showErrorMessage = true,
  variant = 'register',
  inputType = 'text',
  dataCy
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const inputFieldType = isPassword
    ? showPassword
      ? 'text'
      : 'password'
    : inputType === 'numeric'
    ? 'text'
    : 'text';

  const inputMode = inputType === 'numeric' ? 'numeric' : undefined;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputType === 'numeric') {
      if (
        e.key === 'Backspace' ||
        e.key === 'Tab' ||
        e.key === 'Delete' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        e.key === 'Enter'
      ) {
        return;
      }
      if (!/^[0-9]$/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (inputType === 'numeric') {
      const pasteData = e.clipboardData.getData('text');
      if (!/^\d+$/.test(pasteData)) {
        e.preventDefault();
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'numeric') {
      const sanitizedValue = e.target.value.replace(/\D/g, '');
      onChange({
        ...e,
        target: { ...e.target, value: sanitizedValue },
      });
    } else {
      onChange(e);
    }
  };

  const inputStyle = {
    ...FormInputStyles.inputBase,
    ...(variant === 'register'
      ? FormInputStyles.registerInput
      : variant === 'login'
      ? FormInputStyles.loginInput
      : {}),
    ...(isHovered && variant === 'register' ? { boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)' } : {}),
    ...(hasError ? { borderWidth: '1px', borderColor: 'red' } : {}),
    ...style,
  };

  return (
    <div data-cy={dataCy ? `form-input-${dataCy}` : undefined} style={FormInputStyles.container}>
      <label htmlFor={name} style={FormInputStyles.label}>
        {label}
      </label>
      <div style={{ position: 'relative', width: '100%' }}>
        <input
          data-cy={dataCy}
          id={name}
          name={name}
          type={inputFieldType}
          inputMode={inputMode}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          disabled={disabled}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          aria-invalid={hasError}
          aria-describedby={hasError && showErrorMessage ? `${name}-error` : undefined}
          style={inputStyle}
        />
        {isPassword && (
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: 0,
            }}
            aria-label={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
          >
            <img
              src={showPassword ? hide : view}
              alt={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
              style={{ width: '20px', height: '20px' }}
            />
          </button>
        )}
      </div>
      {hasError && showErrorMessage && errorMessage && (
        <span id={`${name}-error`} style={FormInputStyles.errorMessage}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default FormInputComponent;
