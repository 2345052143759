import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import GenericTableComponent from '../components/GenericTableComponent';
import Footer from '../components/Footer';
import userIcon from '../assets/user.png';
import ButtonComponent from '../components/ButtonComponent';
import AvaliationModalComponent from '../components/AvaliationModalComponent';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiFetch } from '../utils/api';
import { Assessment, AssessmentResponse } from '../interfaces/types';
import { toast } from 'react-toastify';
import percentileData from '../assets/percentile_data.json'; // Caminho corrigido
import editIcon from '../assets/edit.png'; // Assegure-se de ter o ícone de edição

// Opcional: Defina uma interface para os dados do percentil, se necessário
export interface PercentileData {
  'Age (months)': number[];
  P5: number[];
  P10: number[];
  P25: number[];
  P50: number[];
  P75: number[];
  P90: number[];
}

const DashboardPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(
    window.matchMedia('(max-width: 820px)').matches
  );
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia('(max-width: 550px)').matches
  );
  const [selectedAssessment, setSelectedAssessment] = useState<Assessment | null>(null);
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const userFullName = Cookies.get('full_name');

  // Função para visualizar a avaliação
  const handleView = (assessment: Assessment) => {
    setSelectedAssessment(assessment);
    setModalOpen(true); // Abre o modal para visualizar
  };

  // Função para editar a avaliação
  const handleEdit = (assessment: Assessment) => {
    navigate('/assessment', { state: { assessment } }); // Navega para a página de edição
  };

  const handleDelete = async (assessment: Assessment) => {
    console.log('handleDelete chamado para:', assessment); // Adicionado para verificação
    if (
      window.confirm(`Deseja realmente excluir a avaliação de ${assessment.patient_name}?`)
    ) {
      try {
        const response = await apiFetch({
          endpoint: `/delete/${assessment.id}`, // Endpoint corrigido, se necessário
          privateRoute: true,
          options: { method: 'DELETE' },
        });
        console.log('Resposta da Exclusão:', response); // Log para verificação

        // Verifica se a resposta é null (o que indica sucesso para DELETE)
        if (response === null) {
          toast.success('Avaliação excluída com sucesso.');

          // Atualiza o estado removendo a avaliação deletada
          setAssessments((prevAssessments) =>
            prevAssessments.filter((a) => a.id !== assessment.id)
          );
        } else {
          // Se a resposta não for null, ainda pode ser um sucesso dependendo da API
          toast.success('Avaliação excluída com sucesso.');
          setAssessments((prevAssessments) =>
            prevAssessments.filter((a) => a.id !== assessment.id)
          );
        }
      } catch (error) {
        console.error('Erro ao excluir avaliação:', error);
        toast.error('Erro ao excluir avaliação.');
      }
    }
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleCreateAssessmentRedirect = () => {
    navigate('/assessment');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    const handleScreenResize = () => {
      setIsMediumScreen(window.matchMedia('(max-width: 820px)').matches);
      setIsSmallScreen(window.matchMedia('(max-width: 550px)').matches);
    };

    const mediaQueryMedium = window.matchMedia('(max-width: 820px)');
    const mediaQuerySmall = window.matchMedia('(max-width: 550px)');

    mediaQueryMedium.addEventListener('change', handleScreenResize);
    mediaQuerySmall.addEventListener('change', handleScreenResize);
    document.addEventListener('mousedown', handleClickOutside);

    const fetchAssessments = async () => {
      let endpoint: string | null = '/assessments/';
      const allAssessments: Assessment[] = [];
      let page = 1;

      while (endpoint) {
        try {
          const data: AssessmentResponse = await apiFetch({
            endpoint,
            privateRoute: true,
            options: { method: 'GET' },
          });

          const userAssessments = data.results || [];
          allAssessments.push(...userAssessments);

          if (data.next) {
            page++;
            const nextUrl = new URL(data.next);
            endpoint = `/assessments/?page=${page}`;
          } else {
            endpoint = null;
          }
        } catch (error) {
          console.error('Erro ao buscar avaliações:', error);
          toast.error('Erro ao buscar avaliações.');
          break;
        }
      }

      const sortedAssessments = allAssessments.sort(
        (a, b) =>
          new Date(b.assessment_date).getTime() - new Date(a.assessment_date).getTime()
      );
      setAssessments(sortedAssessments);
    };

    fetchAssessments();

    return () => {
      mediaQueryMedium.removeEventListener('change', handleScreenResize);
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Novo useEffect para lidar com o estado de navegação para visualização (não necessário se já estiver usando o modal)
  useEffect(() => {
    if (assessments.length === 0) return; // Aguarda as avaliações serem carregadas

    const { childName, motherName } = location.state || {};

    if (childName && motherName) {
      const assessmentToOpen = assessments.find(
        (assessment) => assessment.patient_name === childName
      );

      if (assessmentToOpen) {
        setSelectedAssessment(assessmentToOpen);
        setModalOpen(true);

        navigate(location.pathname, { replace: true, state: {} });
      } else {
        console.warn('Avaliação correspondente não encontrada.');
        toast.error('Avaliação recém-criada não foi encontrada na lista.');
      }
    }
  }, [assessments, location.state, navigate, location.pathname]);

  const getStatusDisplay = (status: Assessment['status']) => {
    switch (status) {
      case 'in_progress':
        return 'Em Progresso';
      case 'done':
        return 'Finalizado';
      case 'stopped':
        return 'Parado';
      default:
        return 'Status Desconhecido';
    }
  };

  const getStatusStyle = (status: Assessment['status']): CSSProperties => {
    let statusStyle: CSSProperties = {
      padding: '4px 8px',
      borderRadius: '12px',
      fontSize: '0.75em',
      display: 'inline-block',
      color: 'white',
      fontFamily: 'Inter',
    };

    switch (status) {
      case 'in_progress':
        statusStyle = {
          ...statusStyle,
          backgroundColor: 'rgba(255, 165, 0, 0.2)',
          color: 'orange',
          border: '1px solid orange',
        };
        break;
      case 'done':
        statusStyle = {
          ...statusStyle,
          backgroundColor: 'rgba(0, 128, 0, 0.2)',
          color: 'green',
          border: '1px solid green',
        };
        break;
      case 'stopped':
        statusStyle = {
          ...statusStyle,
          backgroundColor: 'rgba(255, 0, 0, 0.2)',
          color: 'red',
          border: '1px solid red',
        };
        break;
      default:
        statusStyle = {
          ...statusStyle,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          color: 'black',
          border: '1px solid #ccc',
        };
        break;
    }

    return statusStyle;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <main
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden', // Impede a rolagem no main para gerenciar a rolagem interna
          paddingBottom: '80px', // Ajuste este valor conforme a altura real do footer
        }}
      >
        {/* Navegação do Usuário */}
        <div
          id="user-nav"
          style={{
            display: 'flex',
            minHeight: '14vh',
            alignItems: 'center',
            borderLeft: '1px solid #DEE1E6FF',
            borderBottom: '1px solid #DEE1E6FF',
            padding: '0 1em',
            boxSizing: 'border-box',
            gap: '1em',
          }}
        >
          <div style={{ width: '75%' }}>
            <div
              style={{ fontWeight: 'bold', fontSize: '1.2em', fontFamily: 'Inter' }}
            >
              Avaliações
            </div>
          </div>

          <ButtonComponent
            label="+ Criar avaliação"
            disabled={false}
            style={{ width: '25%', height: '30px', justifyContent: 'center' }}
            onClick={handleCreateAssessmentRedirect}
            dataCy="button-create-assessment"
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1em',
              position: 'relative',
            }}
            ref={dropdownRef}
          >
            <img
              src={userIcon}
              alt="Usuário"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
              onClick={() => setDropdownOpen(!isDropdownOpen)}
            />
            {isDropdownOpen && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  backgroundColor: 'white',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  zIndex: 1,
                  borderRadius: '4px',
                  overflow: 'hidden',
                  marginTop: '8px',
                  minWidth: '120px',
                }}
              >
                <button
                  onClick={handleLogout}
                  style={{
                    padding: '10px 20px',
                    width: '100%',
                    textAlign: 'left',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontFamily: 'Epilogue',
                    fontSize: '1em',
                    color: '#171A1FF',
                    outline: 'none',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
        {/* Área de Conteúdo */}
        <div
          style={{
            padding: '1em',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            boxSizing: 'border-box',
            gap: '2em',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {assessments.length > 0 ? (
              <div
                style={{
                  flex: 1,
                  overflowY: 'auto', // Permite rolagem interna na tabela
                  overflowX: 'hidden',
                }}
              >
                <GenericTableComponent<Assessment>
                  style={{ width: '100%' }}
                  data={assessments}
                  columns={[
                    { header: 'Nome da Criança', accessor: 'patient_name' },
                    { header: 'Idade (semanas)', accessor: 'patient_age_weeks' },
                    {
                      header: 'Profissional',
                      accessor: 'user',
                      render: () => userFullName || 'Profissional Desconhecido',
                    },
                    { header: 'Nome da Mãe', accessor: 'mother_name' },
                    { header: 'Data de Criação', accessor: 'assessment_date' },
                    { header: 'Status', accessor: 'status' },
                  ]}
                  renderStatus={(status: Assessment['status']) => {
                    const displayText = getStatusDisplay(status);
                    const statusStyle = getStatusStyle(status);
                    return <span style={statusStyle}>{displayText}</span>;
                  }}
                  showActionColumn={true}
                  onView={handleView} // Passa a função de visualização
                  onEdit={handleEdit} // Passa a função de edição
                  onDelete={handleDelete}
                  showSearchBar={true}
                  searchPlaceholder="Pesquisar por nome..."
                />
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '1em',
                  color: '#555',
                }}
              >
                Não há dados de avaliação para esse usuário.
              </div>
            )}
          </div>
        </div>
      </main>
      {/* Footer Fixo */}
      <Footer
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#f7fafc',
          minHeight: '60px', // Certifique-se de que este valor corresponda ao paddingBottom
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      />
      {/* Modal de Avaliação */}
      <AvaliationModalComponent
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        assessment={selectedAssessment}
        data={percentileData as PercentileData} // Passando os dados importados e tipados
      />
    </div>
  );
};

export default DashboardPage;
