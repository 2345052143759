import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import ButtonComponent from '../components/ButtonComponent';
import verifyRight from '../assets/verify-right.png';
import emailImage from '../assets/email.png';
import logo from '../assets/logo.png';

interface VerifyLocationPage {
  email: string;
}

const VerifyPage: React.FC = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(
    window.matchMedia("(max-width: 820px)").matches
  );
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 550px)").matches
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>('seuemail@exemplo.com');

  useEffect(() => {
    const handleScreenResize = () => {
      setIsMediumScreen(window.matchMedia("(max-width: 820px)").matches);
      setIsSmallScreen(window.matchMedia("(max-width: 550px)").matches);
    };

    const mediaQueryMedium = window.matchMedia("(max-width: 820px)");
    const mediaQuerySmall = window.matchMedia("(max-width: 550px)");

    mediaQueryMedium.addEventListener('change', handleScreenResize);
    mediaQuerySmall.addEventListener('change', handleScreenResize);
    return () => {
      mediaQueryMedium.removeEventListener('change', handleScreenResize);
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
    };
  }, []);

   useEffect(() => {
     const state = location.state as VerifyLocationPage;
     if (state && state.email) {
       setEmail(state.email);
     } else {
       navigate('/register');
     }
   }, [location, navigate]);

  const handleLoginRedirect = () => {
    navigate('/login');
  };

 return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {/* Logo no topo superior esquerdo */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '1em 1em 1em 3em',
          backgroundColor: 'rgba(235, 253, 255)',
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            maxWidth: '200px',
            height: 'auto',
          }}
        />
      </div>

      <main
        style={{
          backgroundColor: 'rgba(235, 253, 255)',
          display: 'flex',
          flexDirection: isMediumScreen || isSmallScreen ? 'column' : 'row',
          alignItems: 'stretch',
          flex: 1,
        }}
      >
        {/* Contêiner dos Botões e Texto (agora na esquerda) */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: isMediumScreen || isSmallScreen ? '100%' : '50%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2em',
          }}
        >
          {/* Imagem de Email */}
          <div
            style={{
              marginBottom: '1em',
            }}
          >
            <img
              src={emailImage}
              alt="Email"
              style={{
                maxWidth: '60%',
                height: 'auto',
              }}
            />
          </div>

          {/* Título */}
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'Epilogue',
              lineHeight: '1.2',
              fontWeight: '700',
              color: '#171A1FFF',
              fontSize: '1.6em',
              marginBottom: '1em',
            }}
          >
            Verifique seu email
          </div>

          {/* Mensagem */}
          <div
            style={{
              fontFamily: 'Inter',
              color: '#6E7787FF',
              fontWeight: 400,
              lineHeight: '1.5',
              fontSize: '1em',
              textAlign: 'center',
              padding: '1em',
              maxWidth: '75%',
              marginBottom: '1em',
            }}
          >
            Um e-mail foi enviado para <strong>{email}</strong> com um link para
            verificar sua conta. Se você não visualizar o e-mail, verifique sua pasta de spam antes de clicar em "Reenviar".
          </div>

          {/* Botão "Reenviar" */}
          <ButtonComponent
            label="Reenviar"
            disabled={false}
            onClick={() => console.log('Reenviar clicked')}
            style={{
              minHeight: '50px',
              minWidth: '75%',
              justifyContent: 'center',
              marginBottom: '1em',
            }}
            type="button"
          />

          {/* Botão "Ir para o login" */}
          <ButtonComponent
            label="Ir para o login"
            disabled={false}
            onClick={handleLoginRedirect}
            style={{
              minHeight: '50px',
              minWidth: '75%',
              justifyContent: 'center',
            }}
            type="button"
          />
        </div>

        {/* Contêiner das Imagens (agora na direita) */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: isMediumScreen || isSmallScreen ? '100%' : '50%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2em',
          }}
        >
          {/* Imagem Adicional */}
          <div>
            <img
              src={verifyRight}
              alt="Verificar"
              style={{
                maxWidth: '60%',
                height: 'auto',
              }}
            />
          </div>
        </div>
      </main>

      <Footer
        style={{
          textAlign: 'center',
          backgroundColor: '#f7fafc',
          minHeight: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      />
    </div>
  );
};

export default VerifyPage;
