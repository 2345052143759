// src/components/AvaliationModalComponent.tsx

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { apiFetch } from '../utils/api';
import { Stage } from '../utils/STAGES_DATABASE';
import { Assessment } from '../interfaces/types';
import { PercentileData } from '../pages/DashboardPage';
import { sampleSVGPath, mapSVGPointsToChart } from '../utils/svgParser';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

Modal.setAppElement('#root');

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  data: PercentileData;
  assessment: Assessment | null;
}

const AvaliationModalComponent: React.FC<ModalComponentProps> = ({ isOpen, onClose, data, assessment }) => {
  const navigate = useNavigate();
  const [childStages, setChildStages] = useState<Stage[]>([]);
  const [percentileDatasets, setPercentileDatasets] = useState<any[]>([]); // Ajuste o tipo conforme necessário

  const handleAddEvaluation = () => {
    navigate('/assessment', { state: { assessment } });
  };


  // Carregar e mapear os SVGs para datasets
  useEffect(() => {
    const loadSVGData = async () => {
      // Defina as cores para cada percentil
      const percentileColors: { [key: string]: string } = {
        P5: 'rgba(75, 192, 192, 1)',
        P10: 'rgba(153, 102, 255, 1)',
        P25: 'rgba(255, 159, 64, 1)',
        P50: 'rgba(54, 162, 235, 1)',
        P75: 'rgba(255, 206, 86, 1)',
        P90: 'rgba(255, 99, 132, 1)',
      };

      // Defina os nomes dos percentis correspondentes aos SVGs
      const percentiles = [
        { label: 'P5', svgUrl: '/graphs/p5.svg' },
        { label: 'P10', svgUrl: '/graphs/p10.svg' },
        { label: 'P25', svgUrl: '/graphs/p25.svg' },
        { label: 'P50', svgUrl: '/graphs/p50.svg' },
        { label: 'P75', svgUrl: '/graphs/p75.svg' },
        { label: 'P90', svgUrl: '/graphs/p90.svg' },
      ];

      const svgWidth = 400; // Conforme o viewBox do SVG
      const svgHeight = 560;

      // Definir ageRange fixo de 0 a 18.5
      const ageRange = { min: 0, max: 19 };
      const scoreRange = { min: 0, max: 67 }; // Ajuste conforme necessário

      const xOffset = 9; // Define o deslocamento X com base nos SVGs
      const yOffset = -49; // Define o deslocamento Y com base nos SVGs

      const datasetsPromises = percentiles.map(async (percentile) => {
        try {
          const response = await fetch(percentile.svgUrl);
          if (!response.ok) {
            throw new Error(`Não foi possível buscar o SVG para ${percentile.label}`);
          }
          const svgText = await response.text();

          // Parsear o SVG para extrair o atributo 'd' do path
          const parser = new DOMParser();
          const doc = parser.parseFromString(svgText, 'image/svg+xml');
          const path = doc.querySelector('path');
          if (!path) {
            console.error(`Path não encontrado no SVG para ${percentile.label}`);
            return null;
          }
          const d = path.getAttribute('d');
          if (!d) {
            console.error(`Atributo 'd' não encontrado no path para ${percentile.label}`);
            return null;
          }

          // Amostrar pontos ao longo do caminho SVG
          const svgPoints = sampleSVGPath(d, 200); // Aumentado para 200 pontos para maior precisão

          // Mapear os pontos para os valores do gráfico com os offsets
          const chartPoints = mapSVGPointsToChart(svgPoints, svgWidth, svgHeight, ageRange, scoreRange, xOffset, yOffset);

          // Adicionar um ponto extra em x=18 se necessário
          if (chartPoints.length > 0 && chartPoints[chartPoints.length - 1].x < 18) {
            const lastPoint = chartPoints[chartPoints.length - 1];
//            chartPoints.push({ x: 18, y: lastPoint.y });
          }

          return {
            label: percentile.label,
            data: chartPoints,
            borderColor: percentileColors[percentile.label],
            backgroundColor: 'transparent',
            fill: false,
            pointRadius: 0,
          };
        } catch (error) {
          console.error(`Erro ao processar SVG para ${percentile.label}:`, error);
          return null;
        }
      });

      const datasetsResults = await Promise.all(datasetsPromises);
      const validDatasets = datasetsResults.filter(dataset => dataset !== null);

      setPercentileDatasets(validDatasets as any[]); // Ajuste o tipo conforme necessário
    };

    if (isOpen) {
      loadSVGData();
    }
  }, [isOpen, data]);

  // Preparar os dados para o Total Score
  const totalScoreData = React.useMemo(() => {
    if (!assessment) return [];

    const { patient_age_months, score } = assessment;
    return [{ x: patient_age_months, y: score.total_score }];
  }, [assessment]);

  console.log('totalscore', totalScoreData)

  const chartData = {
    labels: data['Age (months)'],
    datasets: [
      ...percentileDatasets,
      {
        label: 'Total Score',
        data: totalScoreData, // Array<number | null>
        borderColor: 'goldenrod',
        backgroundColor: 'yellow',
        pointRadius: 6,
        pointBackgroundColor: 'yellow',
        borderWidth: 2,
        showLine: false,
      },
    ],
  };

  const chartOptions = {
      responsive: true,
      maintainAspectRatio: false, // Permite ajustar o tamanho do gráfico
      plugins: {
          legend: {
              position: 'top' as const,
          },
          title: {
              display: true,
              text: 'Gráficos de Percentis com Total Score',
          },
      },
      scales: {
          x: {
              type: 'linear' as const, // Define o eixo X como linear
              title: {
                  display: true,
                  text: 'Idade (meses)',
              },
              min: 0, // Define o mínimo do eixo X
              max: 19, // Ajustado conforme necessário
              ticks: {
                  stepSize: 1, // Define o passo das marcações no eixo X
              },
          },
          y: {
              title: {
                  display: true,
                  text: 'Score',
              },
              min: 0, // Define o mínimo do eixo Y
              beginAtZero: true, // Garante que o eixo Y comece em 0
              ticks: {
                  stepSize: 10, // Define o passo das marcações no eixo Y
              },
          },
      },
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Gráficos de Percentis"
      style={{
        content: {
          top: '47%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%', // Aumentado para esticar visualmente
          height: '80%', // Ajustado para manter a proporção
        },
      }}
    >
      <div data-cy='avaliation-modal' style={{ width: '100%', height: '100%' }}>
        <Line data={chartData} options={chartOptions} />
      </div>
    </Modal>
  );
};

export default AvaliationModalComponent;
