import { useState, ReactNode, CSSProperties, ChangeEvent, useRef, useEffect } from 'react';
import viewIcon from '../assets/view.png';
import deleteIcon from '../assets/delete.png';
import searchIcon from '../assets/search.svg';
import filterIcon from '../assets/filter.svg';
import editIcon from '../assets/edit.png';

interface Column<T> {
  header: string;
  accessor: keyof T;
  render?: (row: T) => ReactNode;
}

export interface FilterOption {
  label: string;
  value: string;
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  renderStatus?: (value: any) => JSX.Element;
  showSearchBar?: boolean;
  searchPlaceholder?: string;
  showActionColumn?: boolean;
  onView?: (row: T) => void;
  onDelete?: (row: T) => void;
  onEdit?: (row: T) => void;
  style?: CSSProperties;
  additionalFilters?: ReactNode;
  filterConfig?: {
    field: keyof T;
    options: FilterOption[];
  };
  additionalStyle?: CSSProperties;
}

const GenericTableComponent = <T extends { id: number }>({
  data,
  columns,
  renderStatus,
  showSearchBar = false,
  searchPlaceholder,
  showActionColumn = false,
  onView,
  onDelete,
  onEdit,
  style = {},
  additionalFilters,
  filterConfig,
  additionalStyle = {},
}: TableProps<T>) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const rowsPerPage = 10;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('All');
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.matchMedia('(max-width: 550px)').matches
  );

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterSelect = (value: string) => {
    setSelectedFilter(value);
    setIsFilterOpen(false);
    setCurrentPage(1);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setIsFilterOpen(false);
      }
    };
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia('(max-width: 550px)').matches);
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredData = data.filter((row) => {
    const matchesSearch = searchQuery
      ? columns.some((col) => {
          const cellValue = (row as any)[col.accessor];
          if (cellValue && typeof cellValue === 'string') {
            return cellValue.toLowerCase().includes(searchQuery.toLowerCase());
          }
          return false;
        })
      : true;

    const matchesFilter =
      selectedFilter === 'All' || (filterConfig && (row[filterConfig.field] as unknown as string) === selectedFilter);

    return matchesSearch && matchesFilter;
  });

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const renderCellValue = (row: T, accessor: keyof T): ReactNode => {
    const value = row[accessor];
    if (renderStatus && accessor === 'status') {
      return renderStatus(value);
    }
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value !== undefined && value !== null ? String(value) : '';
  };

  const styles = {
    tableContainer: {
      height: '100%',
      width: '100%',
      ...additionalStyle, // Aplicando estilos adicionais
    } as CSSProperties,
    searchContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      marginBottom: '10px',
      paddingRight: '12px',
      position: 'relative',
      gap: '0.5em',
    } as CSSProperties,
    searchInput: {
      width: '200px',
      padding: '8px 12px 8px 36px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '0.9em',
      fontFamily: 'Inter',
      outline: 'none',
      backgroundImage: `url(${searchIcon})`,
      backgroundPosition: '10px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '1.35em 1.35em',
    } as CSSProperties,
    filterButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '8px',
      position: 'relative',
    } as CSSProperties,
    editButton: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '5px',
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      transition: 'opacity 0.3s',
      marginRight: '5px',
    } as CSSProperties,
    filterDropdown: {
      position: 'absolute',
      top: '100%',
      right: isSmallScreen ? 'auto' : '0',
      left: isSmallScreen ? '0' : 'auto',
      width: isSmallScreen ? '100%' : '150px',
      backgroundColor: 'white',
      boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
      zIndex: 1,
      borderRadius: '4px',
      overflow: 'hidden',
      marginTop: '8px',
      minWidth: '150px',
    } as CSSProperties,
    filterOption: {
      padding: '10px 20px',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      width: '100%',
      textAlign: 'left',
      fontFamily: 'Inter',
      fontSize: '0.9em',
      color: '#171A1FFF',
      outline: 'none',
    } as CSSProperties,
    filterOptionHover: {
      backgroundColor: '#f0f0f0',
    } as CSSProperties,
    dataTable: {
      width: '100%',
      borderCollapse: 'collapse',
    } as CSSProperties,
    tableHeader: {
      fontFamily: 'Epilogue',
      fontSize: '0.8em',
      padding: '8px 12px',
      textAlign: 'left',
      color: '#171A1FFF',
      backgroundColor: '#FFFFFFFF',
      fontWeight: 700,
      border: 'none',
      position: 'relative',
    } as CSSProperties,
    tableRow: {
      fontFamily: 'Inter',
      fontSize: '0.75em',
      padding: '8px 12px',
      textAlign: 'left',
      color: '#171A1FFF',
      fontWeight: 400,
      border: 'none',
    } as CSSProperties,
    evenRow: {
      backgroundColor: '#F8F9FAFF',
    } as CSSProperties,
    oddRow: {
      backgroundColor: '#FFFFFFFF',
    } as CSSProperties,
    tag: {
      padding: '4px 8px',
      borderRadius: '12px',
      fontSize: '0.75em',
      display: 'inline-block',
      color: 'white',
      fontFamily: 'Inter',
    } as CSSProperties,
    status: {
      inProgress: {
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        color: 'orange',
        border: '1px solid orange',
      } as CSSProperties,
      completed: {
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        color: 'green',
        border: '1px solid green',
      } as CSSProperties,
      notStarted: {
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        color: 'red',
        border: '1px solid red',
      } as CSSProperties,
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
    } as CSSProperties,
    paginationButton: {
      margin: '0 5px',
      padding: '5px 10px',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: '#fff',
      transition: 'opacity 0.3s',
    } as CSSProperties,
    activeButton: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
    } as CSSProperties,
    actionButton: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '5px',
      cursor: 'pointer',
      marginRight: '5px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      transition: 'opacity 0.3s',
    } as CSSProperties,
    deleteButton: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '5px',
      cursor: 'pointer',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      transition: 'opacity 0.3s',
    } as CSSProperties,
    buttonHover: {
      opacity: 0.7,
    } as CSSProperties,
    icon: {
      width: '1.8em',
      height: '1.8em',
      verticalAlign: 'middle',
    } as CSSProperties,
  };

  const handleViewAction = (row: T) => {
    if (onView) {
      onView(row);
    } else {
      console.log('Visualizar:', row);
    }
  };

  const handleDeleteAction = (row: T) => {
    if (onDelete) {
      onDelete(row);
    } else {
      console.log('Excluir:', row);
    }
  };
  const handleEditAction = (row: T) => {
    if (onEdit) {
      onEdit(row);
    } else {
      console.log('Editar:', row);
    }
  };

  const renderActionButtons = (row: T) => (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {onEdit && (
        <button
          style={styles.editButton}
          onMouseEnter={(e) => {
            (e.currentTarget as HTMLButtonElement).style.opacity = '0.7';
          }}
          onMouseLeave={(e) => {
            (e.currentTarget as HTMLButtonElement).style.opacity = '1';
          }}
          onClick={() => handleEditAction(row)}
          aria-label="Editar"
          data-cy="button-edit-assessment"
        >
          <img src={editIcon} alt="Editar" style={styles.icon} />
        </button>
      )}
      {onView && (
        <button
          style={styles.actionButton}
          onMouseEnter={(e) => {
            (e.currentTarget as HTMLButtonElement).style.opacity = '0.7';
          }}
          onMouseLeave={(e) => {
            (e.currentTarget as HTMLButtonElement).style.opacity = '1';
          }}
          onClick={() => handleViewAction(row)}
          aria-label="Visualizar"
        >
          <img src={viewIcon} alt="Visualizar" style={styles.icon} />
        </button>
      )}
      {onDelete && (
        <button
          style={styles.deleteButton}
          onMouseEnter={(e) => {
            (e.currentTarget as HTMLButtonElement).style.opacity = '0.7';
          }}
          onMouseLeave={(e) => {
            (e.currentTarget as HTMLButtonElement).style.opacity = '1';
          }}
          onClick={() => handleDeleteAction(row)}
          aria-label="Excluir"
          data-cy="button-delete-assessment"
        >
          <img src={deleteIcon} alt="Excluir" style={styles.icon} />
        </button>
      )}
    </div>
  );

  return (
    <div style={{ ...styles.tableContainer, ...additionalStyle }}>
      {/* Aplicando estilos adicionais */}
      {(showSearchBar || filterConfig) && (
        <div style={styles.searchContainer}>
          {filterConfig && (
            <div style={{ position: 'relative' }} ref={filterRef}>
              <button style={styles.filterButton} onClick={handleFilterClick} aria-label="Filtrar">
                <img src={filterIcon} alt="Filtrar" style={styles.icon} />
              </button>
              {isFilterOpen && (
                <div style={styles.filterDropdown}>
                  {filterConfig.options.map((option) => (
                    <button
                      key={option.value}
                      style={styles.filterOption}
                      onClick={() => handleFilterSelect(option.value)}
                      onMouseOver={(e) => {
                        (e.currentTarget as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
                      }}
                      onMouseOut={(e) => {
                        (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'transparent';
                      }}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}

          {showSearchBar && (
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={searchPlaceholder || 'Buscar...'}
              style={styles.searchInput}
              aria-label="Campo de busca"
            />
          )}
        </div>
      )}

      <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
        {/* Ajustado para ocupar todo o espaço disponível */}
        <table style={styles.dataTable}>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index} style={styles.tableHeader}>
                  {col.header}
                </th>
              ))}
              {showActionColumn && <th style={styles.tableHeader}>Ações</th>}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, rowIndex) => {
              const isEven = rowIndex % 2 === 0;
              const rowStyle = isEven ? styles.evenRow : styles.oddRow;

              return (
                <tr
                  key={row.id}
                  style={{ ...rowStyle, border: '1px solid #ccc', borderRadius: '4px' }}
                >
                  {columns.map((col, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        ...styles.tableRow,
                        borderBottom: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                      }}
                    >
                      {col.render ? col.render(row) : renderCellValue(row, col.accessor)}
                    </td>
                  ))}
                  {showActionColumn && (
                    <td
                      style={{
                        ...styles.tableRow,
                        borderBottom: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                      }}
                    >
                      {renderActionButtons(row)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {filteredData.length > rowsPerPage && (
        <div style={styles.pagination}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              ...styles.paginationButton,
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              opacity: currentPage === 1 ? 0.5 : 1,
            }}
            aria-label="Página Anterior"
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              style={{
                ...styles.paginationButton,
                ...(currentPage === i + 1 ? styles.activeButton : {}),
              }}
              onClick={() => handlePageChange(i + 1)}
              aria-label={`Página ${i + 1}`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              ...styles.paginationButton,
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              opacity: currentPage === totalPages ? 0.5 : 1,
            }}
            aria-label="Próxima Página"
          >
            Próxima
          </button>
        </div>
      )}
    </div>
  );
};

export default GenericTableComponent;
