// src/utils/importDevelopmentStages.ts

import { Stage } from '../interfaces/types';
import STAGES_DATABASE from './STAGES_DATABASE';

export const importDevelopmentStages = (): Stage[] => {
  const stages: Stage[] = [];

  for (const [type, stageList] of Object.entries(STAGES_DATABASE)) {
    stageList.forEach((stageData) => {
      const { id, name, description, path } = stageData;
      console.log(`Type: ${type}, Path: ${path}`);

      const photoId = path.split('.')[0];

      // Construir a URL da imagem
      const imagePath = `/assets/development_stages/${type}/${path}`;

      // Verificar se a imagem existe
      // Como a pasta public é servida como estática, não podemos verificar a existência no tempo de execução
      // Portanto, assumiremos que as imagens estão corretas ou usaremos um fallback

      // Para implementar um fallback, você pode adicionar lógica no componente que utiliza essas URLs
      // Aqui, apenas atribuímos a URL diretamente

      const newStage: Stage = {
        id: `${type}-${id}`, // Garante unicidade
        numericId: id,
        name,
        type,
        photoId,
        imagePath,
        description,
      };

      stages.push(newStage);
    });
  }

  // Opcional: Verifica IDs duplicados
  const uniqueStages = stages.filter((stage, index, self) =>
    index === self.findIndex((s) => s.id === stage.id)
  );

  if (uniqueStages.length !== stages.length) {
    console.warn('Duplicate Stage IDs found. Please ensure all IDs are unique.');
  }

  return uniqueStages;
};
