import React, {
  useEffect,
  useState,
  ReactNode,
  useRef,
  CSSProperties,
  ChangeEvent,
  useMemo,
} from 'react';
import filterIcon from '../assets/filter.svg';
import searchIcon from '../assets/search.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export interface FilterOption {
  label: string;
  value: string;
}

interface FilterConfig<T> {
  field: keyof T;
  options: FilterOption[];
}

interface CarouselProps<T> {
  data: T[];
  renderItem: (item: T) => ReactNode;
  show: number;
  infiniteLoop?: boolean;
  searchPlaceholder?: string;
  filterConfig?: FilterConfig<T>;
  style?: CSSProperties;
}

// Ajuste a restrição de tipo para incluir 'type'
const CarouselComponent = <T extends { id: string; type?: string }>({
  data,
  renderItem,
  show,
  searchPlaceholder = 'Buscar...',
  filterConfig,
  style = {},
}: CarouselProps<T>) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('All');
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const [itemsToShow, setItemsToShow] = useState(show);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const mq = window.matchMedia('(hover: none) and (pointer: coarse)');
    setIsTouchDevice(mq.matches);

    const handleResize = () => {
      if (window.innerWidth <= 550) {
        setItemsToShow(1);
      } else {
        setItemsToShow(show);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [show]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterSelect = (value: string) => {
    console.log('Selecionou Filtro:', value);
    setSelectedFilter(value);
    setIsFilterOpen(false);
  };

  const filteredData = useMemo(() => {
    const result = data.filter((item) => {
      const matchesSearch = searchQuery
        ? Object.values(item).some((val) => {
            if (typeof val === 'string') {
              return val.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
          })
        : true;

      const matchesFilter =
        selectedFilter === 'All' ||
        (filterConfig &&
          typeof item[filterConfig.field] === 'string' &&
          (item[filterConfig.field] as string).toLowerCase().trim() ===
            selectedFilter.toLowerCase().trim());

      return matchesSearch && matchesFilter;
    });
    console.log('Dados Filtrados:', result);
    return result;
  }, [data, searchQuery, selectedFilter, filterConfig]);

  useEffect(() => {
    console.log('Filtro Selecionado Atual:', selectedFilter);
  }, [selectedFilter]);

  const carouselContainerStyle: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...style,
  };

  const searchFilterContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '10px',
    paddingRight: '12px',
    position: 'relative',
    gap: '0.5em',
  };

  const searchInputStyle: React.CSSProperties = {
    width: '200px',
    padding: '8px 12px 8px 36px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '0.9em',
    fontFamily: 'Inter, sans-serif',
    outline: 'none',
    backgroundImage: `url(${searchIcon})`,
    backgroundPosition: '10px center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.35em 1.35em',
  };

  const filterButtonStyle: React.CSSProperties = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '8px',
    position: 'relative',
  };

  const filterDropdownStyle: React.CSSProperties = {
    position: 'absolute',
    top: '100%',
    right: '0',
    width: '150px',
    backgroundColor: 'white',
    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
    zIndex: 1000,
    borderRadius: '4px',
    overflow: 'hidden',
    marginTop: '8px',
    minWidth: '150px',
  };

  const filterOptionStyle: React.CSSProperties = {
    padding: '10px 20px',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    width: '100%',
    textAlign: 'left',
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.9em',
    color: '#171A1FFF',
    outline: 'none',
  };

  const itemStyle: React.CSSProperties = {
    width: '100%',
    flexShrink: 0,
    flexGrow: 1,
  };

  const renderFilterDropdown = () => (
    <div style={{ position: 'relative' }} ref={filterRef}>
      <button
        style={filterButtonStyle}
        onClick={handleFilterClick}
        aria-label="Filtrar"
        aria-haspopup="true"
        aria-expanded={isFilterOpen}
      >
        <img src={filterIcon} alt="Filtrar" style={{ width: '1.8em', height: '1.8em' }} />
      </button>
      {isFilterOpen && (
        <div style={filterDropdownStyle} role="menu" aria-label="Opções de filtro">
          <button
            key="all"
            style={filterOptionStyle}
            onClick={() => handleFilterSelect('All')}
            onMouseOver={(e) => {
              (e.currentTarget as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
            }}
            onMouseOut={(e) => {
              (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'transparent';
            }}
            role="menuitem"
          >
            Todas
          </button>
          {filterConfig?.options.map((option) => (
            <button
              key={option.value}
              style={filterOptionStyle}
              onClick={() => handleFilterSelect(option.value)}
              onMouseOver={(e) => {
                (e.currentTarget as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
              }}
              onMouseOut={(e) => {
                (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'transparent';
              }}
              role="menuitem"
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  if (filteredData.length === 0) {
    return (
      <div style={carouselContainerStyle}>
        <div style={searchFilterContainerStyle}>
          {filterConfig && renderFilterDropdown()}

          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={searchPlaceholder}
            style={searchInputStyle}
            aria-label="Campo de busca"
          />
        </div>

        <div style={{ textAlign: 'center', padding: '20px', fontFamily: 'Inter, sans-serif', fontSize: '1em' }}>
          Nenhum item encontrado.
        </div>
      </div>
    );
  }

  return (
    <div style={carouselContainerStyle}>
      <div style={searchFilterContainerStyle}>
        {filterConfig && renderFilterDropdown()}

        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={searchPlaceholder}
          style={searchInputStyle}
          aria-label="Campo de busca"
        />
      </div>
      <Swiper
        key={data.length} // Força re-renderização quando data muda
        slidesPerView={itemsToShow}
        slidesPerGroup={itemsToShow}
        navigation={!isTouchDevice}
        pagination={{ clickable: true }}
        keyboard={{ enabled: true }}
        modules={[Keyboard, Navigation, Pagination]}
        observer={true}
        observeParents={true}
        watchSlidesProgress={true}
        allowTouchMove={true}
        style={{ width: '100%', height: '100%' }}
      >
        {filteredData.map((item) => (
          <SwiperSlide key={item.id}>
            <div style={itemStyle}>{renderItem(item)}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselComponent;
