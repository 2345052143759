// src/utils/api.ts

import Cookies from 'js-cookie';

export const apiFetch = async (
    {
        endpoint,
        privateRoute = false,
        options = {},
    }: {
        endpoint: string;
        privateRoute?: boolean;
        options?: RequestInit;
    }
) => {
    const baseUrl = 'https://escalaemia.com.br/api';
    const token = Cookies.get('authToken');

    const config: RequestInit = {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...(options.headers || {}),
            ...(privateRoute && token ? { 'Authorization': `token ${token}` } : {}),
        },
    };

    const response = await fetch(`${baseUrl}${endpoint}`, config);

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Erro na requisição');
    }

    // Verifica se o status é 204 No Content
    if (response.status === 204) {
        return null;
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        try {
            return await response.json();
        } catch (error) {
            // Caso a análise JSON falhe, retorne null
            return null;
        }
    }

    return null; // Retorna null se não houver conteúdo JSON
};
