// src/utils/svgParser.ts
import { svgPathProperties } from 'svg-path-properties';

// Define uma tupla para representar um ponto (x, y)
type Point = [number, number];

// Define um intervalo com mínimo e máximo
interface Range {
  min: number;
  max: number;
}

// Função para amostrar pontos ao longo do caminho SVG
export const sampleSVGPath = (d: string, numberOfPoints: number = 100): Point[] => {
  const properties = new svgPathProperties(d);
  const length = properties.getTotalLength();
  const points: Point[] = [];

  for (let i = 0; i <= numberOfPoints; i++) {
    const point = properties.getPointAtLength((i / numberOfPoints) * length);
    points.push([point.x, point.y]);
  }

  return points;
};

// Função para mapear os pontos do SVG para os valores do gráfico
export const mapSVGPointsToChart = (
  points: Point[],
  svgWidth: number,
  svgHeight: number,
  ageRange: Range,
  scoreRange: Range,
  xOffset: number, // Deslocamento X
  yOffset: number  // Deslocamento Y
): { x: number; y: number }[] => {
  return points.map(([x, y]: Point) => {
    const adjustedX = x - xOffset; // Subtrai o deslocamento X
    const adjustedY = y - yOffset; // Subtrai o deslocamento Y
    const age = (adjustedX / (svgWidth - xOffset)) * (ageRange.max - ageRange.min) + ageRange.min;
    const score = ((svgHeight - adjustedY) / svgHeight) * (scoreRange.max - scoreRange.min) + scoreRange.min;
    return { x: age, y: score };
  });
};
