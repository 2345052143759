export type Stage = {
    id: string;
    numericId:number;
    name: string;
    type: string;
    photoId: string;
    imagePath: string;
    description: string[];
  };
  
const STAGES_DATABASE = {
prono: [
    {
    id: 0,
    name: 'Deitada em posição prona (1)',
    description: [
        'Flexão fisiológica',
        'Vira a cabeça para tirar o nariz da superfície',
    ],
    path: '1.svg',
    },
    {
    id: 1,
    name: 'Deitada em posição prona (2)',
    description: [
        'Levanta a cabeça assimetricamente em 45 graus',
        'Não consegue manter a cabeça na linha média',
    ],
    path: '2.svg',
    },
    {
    id: 2,
    name: 'Apoiada em posição prona',
    description: [
        'Cotovelo atrás da linha dos ombros',
        'Levanta a cabeça sem apoio em 45 graus',
    ],
    path: '3.svg',
    },
    {
    id: 3,
    name: 'Apoio em antebraço (1)',
    description: [
        'Levanta e mantém a cabeça mais que 45 graus',
        'Cotovelos alinhados com os ombros',
        'Peito elevado',
    ],
    path: '4.svg',
    },
    {
    id: 4,
    name: 'Mobilidade em posição prona',
    description: [
        'Cabeça em 90 graus',
        'Transferência de peso sem controle',
    ],
    path: '5.svg',
    },
    {
    id: 5,
    name: 'Apoio em antebraço (2)',
    description: [
        'Cotovelos à frente dos ombros',
        'Retração ativa do queixo com alongamento do pescoço (chin tuck)',
    ],
    path: '6.svg',
    },
    {
    id: 6,
    name: 'Apoio em braço estendido',
    description: [
        'Braços estendidos',
        'Retração de queixo e peito elevado (chin tuck)',
        'Transferência de peso lateral',
    ],
    path: '7.svg',
    },
    {
    id: 7,
    name: 'Rolar da posição prona para a supina sem rotação',
    description: [
        'Movimento iniciado pela cabeça',
        'O tronco se move como uma unidade',
    ],
    path: '8.svg',
    },
    {
    id: 8,
    name: 'Posição de nado',
    description: ['Padrão extensor ativo'],
    path: '9.svg',
    },
    {
    id: 9,
    name: 'Alcance com apoio em antebraço',
    description: [
        'Transferência ativa de peso para um lado',
        'Alcance controlado com o braço livre',
    ],
    path: '10.svg',
    },
    {
    id: 10,
    name: 'Pivoteio',
    description: [
        'Pivoteia',
        'Movimento em braços e pernas',
        'Flexão lateral do tronco',
    ],
    path: '11.svg',
    },
    {
    id: 11,
    name: 'Rolar da posição prona para a supina com rotação',
    description: ['Rotação de tronco'],
    path: '12.svg',
    },
    {
    id: 12,
    name: 'Ficar de joelhos sobre quatro pontos',
    description: [
        'Pernas flexionadas, abduzidas e em rotação externa',
        'Lordose lombar',
        'Mantém a posição',
    ],
    path: '13.svg',
    },
    {
    id: 13,
    name: 'Apoiada em decúbito lateral',
    description: [
        'Dissociação de pernas',
        'Estabilidade de ombros',
        'Rotação no eixo do corpo',
    ],
    path: '14.svg',
    },
    {
    id: 14,
    name: 'Rastejar recíproco',
    description: [
        'Movimentos recíprocos de braço e de perna com rotação de tronco',
    ],
    path: '15.svg',
    },
    {
    id: 15,
    name:
        'Ajoelhar-se sobre quatro pontos para sentar-se ou para semi-sentar-se',
    description: [
        'Entra e sai dessa posição',
        'Pode conseguir sentar-se',
    ],
    path: '16.svg',
    },
    {
    id: 16,
    name: 'Engatinha recíproco (1)',
    description: [
        'Abdução de pernas com rotação externa',
        'Lordose lombar: transferência de peso para os lados com flexão lateral do tronco',
    ],
    path: '17.svg',
    },
    {
    id: 17,
    name: 'Alcance a partir de apoio de braço em extensão',
    description: [
        'Alcança com o braço em extensão',
        'Rotação de tronco',
    ],
    path: '18.svg',
    },
    {
    id: 18,
    name: 'Ficar de joelhos sobre quatro pontos (2)',
    description: [
        'Quadris alinhados sob a pelve',
        'Alinhamento da coluna lombar',
    ],
    path: '19.svg',
    },
    {
    id: 19,
    name: 'Posição de joelhos sobre quatro pontos modificada',
    description: [
        'Brinca nessa posição',
        'Pode mover-se para a frente',
    ],
    path: '20.svg',
    },
    {
    id: 20,
    name: 'Engatinhar recíproco (2)',
    description: [
        'Coluna lombar alinhada',
        'Move-se com rotação de tronco',
    ],
    path: '21.svg',
    },
],
supino: [
    {
    id: 0,
    name: 'Deitada em posição supina (1)',
    description: [
        'Flexão fisiológica',
        'Rotação de cabeça: mão na boca',
        'Movimentos aleatórios de braços e pernas',
    ],
    path: '1.svg',
    },
    {
    id: 1,
    name: 'Deitada em posição supina (2)',
    description: [
        'Rotação da cabeça para a linha média',
        'RTCA não obrigatório',
    ],
    path: '2.svg',
    },
    {
    id: 2,
    name: 'Deitada em posição supina (3)',
    description: [
        'Cabeça na linha média',
        'Movimenta os braços, mas não consegue levar as mãos para a linha média',
    ],
    path: '3.svg',
    },
    {
    id: 3,
    name: 'Deitada em posição supina (4)',
    description: [
        'Ativação dos flexores cervicais, retração do queixo (chin tuck)',
        'Leva as mãos para a linha média',
    ],
    path: '4.svg',
    },
    {
    id: 4,
    name: 'Mãos nos joelhos',
    description: [
        'Retração do queixo (chin tuck)',
        'Alcança os joelhos com as mãos',
        'Ativação dos músculos abdominais',
    ],
    path: '5.svg',
    },
    {
    id: 5,
    name: 'Extensão ativa',
    description: ['Empurra-se para extensão com as pernas'],
    path: '6.svg',
    },
    {
    id: 6,
    name: 'Mãos nos pés',
    description: [
        'Pode manter as pernas em elevação',
        'Apresenta mobilidade pélvica',
    ],
    path: '7.svg',
    },
    {
    id: 7,
    name: 'Rolar da posição supina para a prona sem rotação',
    description: [
        'Retificação lateral da cabeça',
        'O tronco se move como uma unidade',
    ],
    path: '8.svg',
    },
    {
    id: 8,
    name: 'Rolar da posição supina para a prona com rotação',
    description: ['Rotação de tronco'],
    path: '9.svg',
    },
],
sentado: [
    {
    id: 0,
    name: 'Sentada com apoio',
    description: [
        'Levanta e mantém brevemente a cabeça em linha média',
    ],
    path: '1.svg',
    },
    {
    id: 1,
    name: 'Sentada com apoio sobre os braços',
    description: [
        'Mantém a cabeça em linha média',
        'Mantém apoio sobre os braços brevemente',
    ],
    path: '2.svg',
    },
    {
    id: 2,
    name: 'Empurra-se para sentar-se',
    description: [
        'Retração do queixo; cabeça alinhada ou em frente ao corpo (chin tuck)',
    ],
    path: '3.svg',
    },
    {
    id: 3,
    name: 'Sentada sem sustentação',
    description: [
        'Adução da escápula e extensão do úmero',
        'Não consegue manter a posição',
    ],
    path: '4.svg',
    },
    {
    id: 4,
    name: 'Sentada com apoio de braços',
    description: [
        'Coluna torácica estendida',
        'Movimentos da cabeça independentes do tronco: apoio sobre braços estendidos',
    ],
    path: '5.svg',
    },
    {
    id: 5,
    name: 'Sentada sem sustentação e sem apoio de braço',
    description: [
        'Não consegue ficar na posição sentada sozinha por muito tempo',
    ],
    path: '6.svg',
    },
    {
    id: 6,
    name: 'Transferência de peso na posição sentada sem sustentação',
    description: [
        'Transferência de peso para frente, para trás e para os lados',
        'Não consegue ser deixada sozinha na posição sentada',
    ],
    path: '7.svg',
    },
    {
    id: 7,
    name: 'Sentada sem apoio de braço (1)',
    description: [
        'Os braços se movem longe do corpo',
        'Pode brincar nesta posição com um brinquedo',
        'Pode ser deixada sozinha na posição sentada',
    ],
    path: '8.svg',
    },
    {
    id: 8,
    name: 'Alcance com rotação na posição sentada',
    description: [
        'Senta-se independentemente',
        'Alcança um brinquedo com rotação do tronco',
    ],
    path: '9.svg',
    },
    {
    id: 9,
    name: 'Da posição sentada para a posição prona',
    description: [
        'Desloca-se da posição sentada para alcançar a posição prona',
        'Puxa-se com os braços; pernas inativas',
    ],
    path: '10.svg',
    },
    {
    id: 10,
    name: 'Da posição sentada para a posição de joelhos sobre quatro pontos',
    description: [
        'Levanta ativamente a pelve, as nádegas e a perna que não é usada para apoio para assumir a posição de joelhos em quatro pontos',
    ],
    path: '11.svg',
    },
    {
    id: 11,
    name: 'Sentada sem apoio de braço (2)',
    description: [
        'A posição das pernas é variável',
        'A criança assume e sai das posições facilmente',
    ],
    path: '12.svg',
    },
],
ortostatico: [
    {
    id: 0,
    name: 'Em pé com apoio (1)',
    description: [
        'Pode haver flexão intermitente de joelho e quadril',
    ],
    path: '1.svg',
    },
    {
    id: 1,
    name: 'Em pé com apoio (2)',
    description: [
        'Cabeça alinhada com o corpo',
        'Quadris atrás dos ombros',
        'Movimento variável de pernas',
    ],
    path: '2.svg',
    },
    {
    id: 2,
    name: 'Em pé com apoio (3)',
    description: [
        'Quadris alinhados com os ombros',
        'Controle ativo do tronco',
        'Movimentos variáveis de pernas',
    ],
    path: '3.svg',
    },
    {
    id: 3,
    name: 'Puxar-se para a posição em pé com apoio',
    description: [
        'Empurra-se com os braços e joelhos estendidos',
    ],
    path: '4.svg',
    },
    {
    id: 4,
    name: 'Puxar-se para a posição em pé e ficar em pé',
    description: [
        'Empurra-se para a posição em pé: transfere o peso de um lado para o outro',
    ],
    path: '5.svg',
    },
    {
    id: 5,
    name: 'Em pé com apoio e rotação',
    description: ['Rotação de tronco e pelve'],
    path: '6.svg',
    },
    {
    id: 6,
    name: 'Marcha lateral com apoio sem rotação',
    description: ['Anda de um lado para o outro sem rotação'],
    path: '7.svg',
    },
    {
    id: 7,
    name: 'Semiajoelhada',
    description: [
        'Pode assumir a posição em pé ou manter-se brincando nesta posição',
    ],
    path: '8.svg',
    },
    {
    id: 8,
    name: 'Abaixar-se com controle a partir da posição em pé',
    description: [
        'Abaixa-se com controle a partir da posição em pé',
    ],
    path: '9.svg',
    },
    {
    id: 9,
    name: 'Marcha lateral com apoio e rotação',
    description: ['Anda de um lado para o outro com rotação'],
    path: '10.svg',
    },
    {
    id: 10,
    name: 'Em pé de forma independente',
    description: [
        'Fica em pé sozinha por algum tempo',
        'Reações de equilíbrio nos pés',
    ],
    path: '11.svg',
    },
    {
    id: 11,
    name: 'Primeiros passos',
    description: [
        'Anda independentemente; move-se rapidamente com passos curtos',
    ],
    path: '12.svg',
    },
    {
    id: 12,
    name:
        'Para a posição em pé a partir da posição agachada modificada',
    description: [
        'Move-se da posição agachada para a posição em pé com flexão e extensão adequadas de quadris e joelhos',
    ],
    path: '13.svg',
    },
    {
    id: 13,
    name: 'Para a posição em pé a partir da posição quadrúpede',
    description: [
        'Empurra-se rapidamente com as mãos para alcançar a posição em pé',
    ],
    path: '14.svg',
    },
    {
    id: 14,
    name: 'Marcha independente',
    description: ['Anda com independência'],
    path: '15.svg',
    },
    {
    id: 15,
    name: 'Posição agachada',
    description: [
        'Mantém a posição por meio de reações de equilíbrio dos pés e da posição do tronco',
    ],
    path: '16.svg',
    },
],
};

export default STAGES_DATABASE;
