import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import LoginFormComponent from '../components/LoginFormComponent';

// Função auxiliar para importar todas as imagens de um diretório
function importAll(r: any) {
  return r.keys().map(r);
}

// Importar todas as imagens dos subdiretórios
// Atualize os caminhos relativos conforme necessário
const imagesPronado = importAll(require.context('../assets/development_stages/prono', false, /\.(svg)$/));
const imagesSupino = importAll(require.context('../assets/development_stages/supino', false, /\.(svg)$/));
const imagesSentado = importAll(require.context('../assets/development_stages/sentado', false, /\.(svg)$/));
const imagesOrtostatico = importAll(require.context('../assets/development_stages/ortostatico', false, /\.(svg)$/));

const allImages = [
  ...imagesPronado,
  ...imagesSupino,
  ...imagesSentado,
  ...imagesOrtostatico,
];

// Função para selecionar N imagens aleatórias
function getRandomImages(arr: any, n: any) {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
}

const LoginPage: React.FC = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(window.matchMedia("(max-width: 820px)").matches);
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 550px)").matches);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [backgroundImages, setBackgroundImages] = useState<string[]>([]);
  const [backgroundImage, setBackgroundImage] = useState<string>('');

  useEffect(() => {
    const handleScreenResize = () => {
      setIsMediumScreen(window.matchMedia("(max-width: 820px)").matches);
      setIsSmallScreen(window.matchMedia("(max-width: 550px)").matches);
    };

    const mediaQueryMedium = window.matchMedia("(max-width: 820px)");
    const mediaQuerySmall = window.matchMedia("(max-width: 550px)");

    mediaQueryMedium.addEventListener('change', handleScreenResize);
    mediaQuerySmall.addEventListener('change', handleScreenResize);
    return () => {
      mediaQueryMedium.removeEventListener('change', handleScreenResize);
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
    };
  }, []);

  useEffect(() => {
    // Selecionar 9 imagens aleatórias ao montar o componente
    setBackgroundImages(getRandomImages(allImages, 9));
  }, []);

  useEffect(() => {
    if (backgroundImages.length > 0) {
      // Configurar o intervalo para mudar a imagem a cada 5 segundos
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === backgroundImages.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);

      // Limpar o intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    }
  }, [backgroundImages]);


  useEffect(() => {
    // Selecionar 9 imagens aleatórias ao montar o componente
    const selectedImages = getRandomImages(allImages, 36);

    // Função para criar a imagem composta
    const createCompositeImage = async () => {
      const canvasSize = 1900; // Tamanho do canvas (ajuste conforme necessário)
      const gridSize = 6; // Grid 3x3
      const cellSize = canvasSize / gridSize;

      const canvas = document.createElement('canvas');
      canvas.width = canvasSize;
      canvas.height = canvasSize;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        // Carregar todas as imagens
        const imageElements = await Promise.all(
          selectedImages.map(
            (src: any) =>
              new Promise<HTMLImageElement>((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
              })
          )
        );

        // Desenhar as imagens no canvas
        for (let i = 0; i < imageElements.length; i++) {
          const img = imageElements[i];
          const x = (i % gridSize) * cellSize;
          const y = Math.floor(i / gridSize) * cellSize;
          ctx.drawImage(img, x, y, cellSize, cellSize);
        }

        // Obter o data URL da imagem composta
        const dataURL = canvas.toDataURL();
        setBackgroundImage(dataURL);
      }
    };

    createCompositeImage().catch((error) => {
      console.error('Erro ao criar a imagem composta:', error);
    });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <main
        style={{
          backgroundColor: 'rgba(235, 253, 255)',
          backgroundImage: backgroundImage
            ? `linear-gradient(rgba(0, 189, 214, 0.3), rgba(0, 189, 214, 0.3)), url('${backgroundImage}')`
            : undefined,
          backgroundSize: 'cover',
          display: 'flex',
          height: '90%',
          flexDirection: (isMediumScreen || isSmallScreen) ? 'column' : 'row',
          overflow: (isMediumScreen || isSmallScreen) ? 'scroll' : 'hidden',
          alignItems: 'center',
          width: '100%',
          padding: '2em',
        }}
      >
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center'
        }}>
          <LoginFormComponent
            style={{
              width: (isMediumScreen || isSmallScreen) ? '50%' : '25%',
              maxWidth: '400px',
              position: (isMediumScreen || isSmallScreen) ? 'relative' : undefined,
              left: (isMediumScreen || isSmallScreen) ? -30 : undefined,
            }} 
          />
        </div>
      </main>

      <Footer style={{
        textAlign: 'center',
        backgroundColor: '#f7fafc',
        minHeight: '3%',
        display: 'flex',
        justifyContent: 'center'
      }} />
    </div>
  );
};


export default LoginPage;
