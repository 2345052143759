import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import RegisterFormComponent from '../components/RegisterFormComponent';
import logo from '../assets/logo.png';
import lines from '../assets/lines.svg';
import lollilop from '../assets/lollilop.svg';
import globe from '../assets/globe.svg';

const RegisterPage: React.FC = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(window.matchMedia("(max-width: 820px)").matches);
  const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia("(max-width: 550px)").matches);

  useEffect(() => {
    const handleScreenResize = () => {
      setIsMediumScreen(window.matchMedia("(max-width: 820px)").matches);
      setIsSmallScreen(window.matchMedia("(max-width: 550px)").matches);
    };

    const mediaQueryMedium = window.matchMedia("(max-width: 820px)");
    const mediaQuerySmall = window.matchMedia("(max-width: 550px)");

    mediaQueryMedium.addEventListener('change', handleScreenResize);
    mediaQuerySmall.addEventListener('change', handleScreenResize);
    return () => {
      mediaQueryMedium.removeEventListener('change', handleScreenResize);
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
    };
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
      <main  style={{
        backgroundColor: 'rgba(235, 253, 255)',
        display: 'flex',
        height: '90%',
        flexDirection: (isMediumScreen || isSmallScreen)? 'column': 'row',
        overflow: (isMediumScreen || isSmallScreen)? 'scroll' : 'hidden',
        
      }}>
        <div 
          id='main-container-right'
          style={{
            display:'flex',
            flexDirection: 'column',
            width: (isMediumScreen || isSmallScreen)  ? 'auto' : '50%',
            order: (isMediumScreen || isSmallScreen) ? 1 : 2,
            justifyContent: 'space-evenly',
            alignItems:'center',
            padding: '2em'
          }}
        >
          <div >
            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', display: 'flex' }} >
              <img
                src={logo}
                alt="Logo"
                style={{
                  maxWidth: (isMediumScreen || isSmallScreen)? '45%' : '45%',
                  maxHeight: (isMediumScreen || isSmallScreen)? '45%' : '45%' ,
                  padding: '2em 2em 2em 2em',
                  fill: 'white',
                  stroke: 'white',
                  strokeWidth: 10
                }}
              />
            </div>

            <div style={{
              minHeight: '5%',
              flex:1,
              fontFamily: 'Epilogue',
              fontWeight: 600,
              fontSize: '2em',
              lineHeight: '2.02em',
              flexDirection: 'column',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: (isMediumScreen || isSmallScreen) ? undefined : -20,
              left: (isMediumScreen || isSmallScreen) ? undefined : -3
            }}>
              Olá!
              <img
                src={lines}
                alt="Logo"
                style={{
                  position: 'relative',
                  top: (isMediumScreen || isSmallScreen) ? undefined : -15,
                  maxWidth: '25%',
                  maxHeight: '25%',
                  transform: 'rotate(354deg)', 
                }}
              />
            </div>
          </div>

          <div style={{ display:'flex', gap: '2em', paddingTop: (isMediumScreen || isSmallScreen) ? '1.5em' : '0.3em', paddingRight: '3em', paddingBottom: (isMediumScreen || isSmallScreen) ? '2.5em' : '1em', paddingLeft: (isMediumScreen || isSmallScreen) ? '1em' : undefined }}>
            <div>
              <img
                src={lollilop}
                alt="Lollipop"
                style={{
                  width: (isMediumScreen || isSmallScreen) ? '100%' : '70%',
                  height: (isMediumScreen || isSmallScreen) ? '100%' : '70%',
                  transform: 'rotate(354deg)', 
                }}
              />
            </div>
            <div>
            Seja muito bem-vindo(a) ao aplicativo da Escala Motora Infantil de Alberta (EMIA). 
            A EMIA foi contruída para mensurar o desenvolvimento motor de lactentes desde o nascimento até os 18 meses de idade – ou até a marcha independente. 
            Os principais usos da Escala são para: 
              <ul>
                <li>
                  Identificar lactentes com atrasos ou desvios no desenvolvimento motor; 
                  avaliação do desenvolvimento ou amadurecimento motor ao longo do tempo. 
                </li>
                <li>
                Avaliação do desenvolvimento ou amadurecimento motor ao longo do tempo. 
                  </li>
              </ul>
              
            </div>
          </div>

          <div style={{ display:'flex', gap: '2em', paddingTop: '0.3em', paddingRight: '3em', paddingBottom: (isMediumScreen || isSmallScreen) ? '2.5em' : '5em', paddingLeft: (isMediumScreen || isSmallScreen) ? '1em' : undefined }}>
            <div>
              <img
                src={globe}
                alt="Globe"
                style={{
                  width: (isMediumScreen || isSmallScreen) ? '100%' : '70%',
                  height: (isMediumScreen || isSmallScreen) ? '100%' : '70%',
                }}
              />
            </div>
            <div>
            Inscreva-se agora e comece a utilizar o aplicativo da EMIA, é gratuito! 
            Essa é uma iniciativa do Núcleo de Estudos em Neuropediatria e Motricidade (NENEM), 
            coordenado pela Profa. Dra. Eloisa Tudella.
            </div>
          </div>
        </div>

        <div 
          id='main-container-left'
          style={{
            display: 'flex',
            width: (isMediumScreen || isSmallScreen) ? 'auto' : '50%',
            justifyContent: 'center',
            padding: '2em',
            alignItems: 'center',
            flexGrow: 1,
            flexShrink: 1,
            order: (isMediumScreen || isSmallScreen) ? 2 : 1,

          }}
        >
          <RegisterFormComponent
            style={{
              minWidth: isSmallScreen ? '320px' : isMediumScreen ? '650px' : '70%',
              flexGrow:1,
              flexShrink: 1,
              minHeight: '90%',
              display: 'flex',
              flexDirection: 'column',
            }}
          />
        </div>
      </main>

      <Footer style={{
        textAlign: 'center',
        backgroundColor: '#f7fafc',
        minHeight: '3%',
        display:'flex',
        justifyContent:'center'
      }} />
    </div>
  );
};

export default RegisterPage;
