import React, { CSSProperties, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import FormInputComponent from './FormInputComponent';
import ButtonComponent from './ButtonComponent';
import Cookies from 'js-cookie';
import { apiFetch } from '../utils/api';
import { toast } from 'react-toastify'; // Adicionado
interface LoginFormComponentProp {
  style?: CSSProperties;
}

interface LoginResponse {
  token: string;
  full_name: string;

}
interface JwtPayload {
  user_id: number;
  // Adicione outros campos conforme o payload do seu token
}

export default function LoginFormComponent({ style = {} }: LoginFormComponentProp) {
  const navigate = useNavigate();

  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Resetar erros anteriores
    setUsernameError('');
    setPasswordError('');

    // Validação simples
    let valid = true;
    if (userName.trim() === '') {
      setUsernameError('Nome de usuário é obrigatório.');
      valid = false;
    }
    if (password.trim() === '') {
      setPasswordError('Senha é obrigatória.');
      valid = false;
    }
    if (!valid) return;

    try {
      const data: LoginResponse = await apiFetch(
        {
          endpoint: `/login/`,
          privateRoute: false,
          options:  {
            method: 'POST',
            body: JSON.stringify({
              username: userName,
              password: password,
            }),
          }});

      if (data && data.token) {
        // Armazenar o token nos cookies (expira em 1 dia)
        Cookies.set('authToken', data.token, { expires: 1 });
        Cookies.set('full_name', data.full_name, {expires: 1})

        navigate('/dashboard');
        toast.success('Login realizado com sucesso!', { toastId: 'login-success' }); 
      }     
    } catch (error: any) {
      toast.error('Falha na autenticação. Tente novamente.', {toastId: 'login-error'});
    }
  };
  return (
    <div 
    data-cy="login-form-container"
    style={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: '3em',
      borderRadius: '8px',
      boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
      width: '25%',
      height: '80%',
      margin: '0 auto',
      ...style
    }}>
      <div 
      data-cy='login-form-header'
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Epilogue',
        fontSize: '2em',
        lineHeight: '68px',
        fontWeight: 700,
        color: '#171A1FF',
        textAlign: 'center'
      }}>
        Olá.
        <Link to="/register" 
          style={{
            position: 'relative',
            top: -12,
            fontFamily: 'Epilogue',
            fontSize: '0.4em',
            lineHeight: '28px',
            fontWeight: 700,
            color: '#9095A0FF',
            width: '100%',
            textAlign: 'center'
          }}>
          Crie uma conta.
        </Link>
      
      </div>

      <form onSubmit={handleSubmit}>
        <FormInputComponent
          dataCy='input-username'
          name="username"
          label="Username"
          placeholder="username"
          value={userName}
          onChange={(e) => setUsername(e.target.value)}
          hasError={!!usernameError}
          errorMessage={usernameError}
          variant="login"
        />
        <FormInputComponent
          name="password"
          label="Senha"
          placeholder="senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          hasError={!!passwordError}
          errorMessage={passwordError}
          isPassword={true}
          variant="login"
          dataCy='input-password'
        />
        <div style={{paddingTop: '1em', paddingLeft: '0.6em'}}>
          <Link to="/" 
            style={{
              position: 'relative',
              top: -12,
              fontFamily: 'Epilogue',
              fontSize: '0.8em',
              lineHeight: '28px',
              fontWeight: 700,
              color: '#9095A0FF',
              width: '100%',
              textAlign: 'center'
          }}>
            Esqueceu a senha?
          </Link>
        </div>

        <ButtonComponent
          label="ENTRAR"
          disabled={false}
          style={{
            width: '100%',
            height: '30px',
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
          }}
          type="submit"
          dataCy="button-login"
        />
      </form>
    </div>
  );
}
