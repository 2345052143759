import React, { useEffect, useRef, useState, ChangeEvent, useMemo } from 'react';
import Footer from '../components/Footer';
import userIcon from '../assets/user.png';
import ButtonComponent from '../components/ButtonComponent';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import FormInputComponent from '../components/FormInputComponent';
import { importDevelopmentStages } from '../utils/importDevelopmentStages';
import { FaCheckCircle } from 'react-icons/fa';
import CarouselComponent from '../components/CarouselComponent';
import { Assessment, Stage } from '../interfaces/types';
import { CreateAssessmentPayload, CreateScore } from '../interfaces/types';
import { generateRandomString } from '../utils/generateRandomString';
import { toast } from 'react-toastify';

type StageSelectedType = {
  id: string;
  type: string;
};

type ScoreArrayKeys = 'prone' | 'supine' | 'sitting' | 'standing';

const CreateAssessmentPage = () => {
  const location = useLocation();
  const assessmentData = location.state?.assessment;
  const isEditMode = Boolean(assessmentData);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia('(max-width: 821px)').matches
  );
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [patientName, setPatientName] = useState(assessmentData?.patient_name || '');
  const [motherName, setMotherName] = useState(assessmentData?.mother_name || '');
  const [ageWeeks, setAgeWeeks] = useState(assessmentData?.patient_age_weeks.toString() || '');
  const [ageMonths, setAgeMonths] = useState(assessmentData?.patient_age_months.toString() || '');
  const [isPremature, setIsPremature] = useState(
    assessmentData?.patient_age_weeks_adjusted > 0 || false
  );
  const [prematureAgeWeeks, setPrematureAgeWeeks] = useState(
    assessmentData?.patient_age_weeks_adjusted?.toString() || ''
  );

  const [patientNameError, setPatientNameError] = useState('');
  const [ageWeeksError, setAgeWeeksError] = useState('');
  const [ageMonthsError, setAgeMonthsError] = useState('');
  const [prematureAgeWeeksError, setPrematureAgeWeeksError] = useState('');
  const [motherNameError, setMotherNameError] = useState('');

  const [stagesSelected, setStagesSelected] = useState<StageSelectedType[]>([]);
  const [selectedType, setSelectedType] = useState<string>('prono');
  const [developmentStages, setDevelopmentStages] = useState<Stage[]>([]);

  const addStagesRef = useRef<HTMLDivElement>(null);
  const generalInfoRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    Cookies.remove('authToken');
    navigate('/login');
  };

  const handleReturnDashboard = () => {
    navigate('/dashboard');
  };

  const handleRemoveStage = (stageId: string) => {
    setStagesSelected((prev) => prev.filter((s) => s.id !== stageId));
  };

  const handleAddStage = (stage: Stage) => {
    setStagesSelected((prev) => {
      if (!prev.some((s) => s.id === stage.id)) {
        return [...prev, { id: stage.id, type: stage.type }];
      }
      return prev;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    const handleScreenResize = () => {
      setIsSmallScreen(window.matchMedia('(max-width: 550px)').matches);
    };

    const mediaQuerySmall = window.matchMedia('(max-width: 550px)');

    mediaQuerySmall.addEventListener('change', handleScreenResize);
    document.addEventListener('mousedown', handleClickOutside);

    try {
      const stages = importDevelopmentStages();
      stages.sort((a, b) => a.numericId - b.numericId);
      console.log('Estágios Importados:', stages);
      setDevelopmentStages(stages);
    } catch (error) {
      console.error('Erro ao importar estágios de desenvolvimento:', error);
    }

    return () => {
      mediaQuerySmall.removeEventListener('change', handleScreenResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Preencher estágios selecionados no modo de edição
  useEffect(() => {
    if (isEditMode && assessmentData?.score && developmentStages.length > 0) {
      const selectedStages: StageSelectedType[] = [];

      // Mapeamento inverso dos tipos
      const inverseTypeMapping: Record<ScoreArrayKeys, string> = {
        prone: 'prono',
        supine: 'supino',
        sitting: 'sentado',
        standing: 'ortostatico',
      };

      // Mapeie os scores para os estágios selecionados
      Object.keys(assessmentData.score).forEach((key) => {
        // Ignora chaves que não terminam com '_score'
        if (!key.endsWith('_score')) return;

        // Remove '_score' do final da chave
        const keyWithoutSuffix = key.replace('_score', '');
        const stageTypeKey = keyWithoutSuffix as ScoreArrayKeys;

        const stageNumericIds = assessmentData.score[key];

        // Obtenha o tipo de estágio correspondente
        const stageType = inverseTypeMapping[stageTypeKey];

        if (Array.isArray(stageNumericIds)) {
          stageNumericIds.forEach((numericId: any) => {
            const stage = developmentStages.find(
              (s) => s.numericId === numericId && s.type === stageType
            );
            if (stage) {
              selectedStages.push({ id: stage.id, type: stage.type });
            }
          });
        }
      });

      setStagesSelected(selectedStages);
    }
  }, [isEditMode, assessmentData, developmentStages]);

  useEffect(() => {
    console.log('stagesSelected atualizado:', stagesSelected);
  }, [stagesSelected]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;
    if (!patientName) {
      setPatientNameError('Nome do paciente é obrigatório.');
      isValid = false;
    } else {
      setPatientNameError('');
    }
    if (!motherName) {
      setMotherNameError('Nome da mãe é obrigatório.');
      isValid = false;
    } else {
      setMotherNameError('');
    }

    if (!ageWeeks) {
      setAgeWeeksError('Idade em semanas é obrigatória.');
      isValid = false;
    } else if (isNaN(Number(ageWeeks)) || Number(ageWeeks) < 0) {
      setAgeWeeksError('Insira um número válido.');
      isValid = false;
    } else {
      setAgeWeeksError('');
    }

    if (!ageMonths) {
      setAgeMonthsError('Idade em meses é obrigatória.');
      isValid = false;
    } else if (isNaN(Number(ageMonths)) || Number(ageMonths) < 0) {
      setAgeMonthsError('Insira um número válido.');
      isValid = false;
    } else {
      setAgeMonthsError('');
    }

    if (isPremature) {
      if (!prematureAgeWeeks) {
        setPrematureAgeWeeksError('Idade do bebê prematuro é obrigatória.');
        isValid = false;
      } else if (isNaN(Number(prematureAgeWeeks)) || Number(prematureAgeWeeks) < 0) {
        setPrematureAgeWeeksError('Insira um número válido.');
        isValid = false;
      } else {
        setPrematureAgeWeeksError('');
      }
    } else {
      setPrematureAgeWeeksError('');
    }

    if (isValid) {
      // Mapeamento de tipos para Score
      const typeMapping: Record<string, ScoreArrayKeys> = {
        prono: 'prone',
        supino: 'supine',
        sentado: 'sitting',
        ortostatico: 'standing',
      };

      // Inicialização completa do objeto Score
      const scores: CreateScore = {
        prone: [],
        supine: [],
        sitting: [],
        standing: [],
      };

      // População do Score com base nos estágios selecionados
      stagesSelected.forEach((stageSelected) => {
        const stage = developmentStages.find((s) => s.id === stageSelected.id);
        if (stage) {
          const type = typeMapping[stage.type];
          const numericId = stage.numericId;
          scores[type].push(numericId);
        }
      });



      try {
        const authToken = Cookies.get('authToken');
        let response: Response;

        if (isEditMode) {

          const formData = {
            id: assessmentData.id,
            patient_name: assessmentData.patient_name,
            patient_age_weeks: assessmentData.patient_age_weeks,
            patient_age_months: assessmentData.patient_age_weeks,
            patient_age_weeks_adjusted: assessmentData.patient_age_weeks_adjusted,
            patient_age_months_adjusted: assessmentData.patient_age_months_adjusted,
            mother_name: assessmentData.mother_name,
            registry: assessmentData.registry,
            status: 'done',
            score: {
              patient_age_months: assessmentData.patient_age_months,
              prone: scores.prone,
              sitting: scores.sitting,
              standing:scores.standing,
              supine: scores.supine
            }
            
          }
          // Envio para o endpoint de atualização
          response = await fetch(
            `https://escalaemia.com.br/api/update/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
              body: JSON.stringify(formData),
            }
          );
        } else {
          const formData: CreateAssessmentPayload = {
            patient_name: patientName,
            patient_age_weeks: Number(ageWeeks),
            patient_age_months: Number(ageMonths),
            patient_age_weeks_adjusted: isPremature ? Number(prematureAgeWeeks) : 0,
            patient_age_months_adjusted: isPremature ? Number(ageMonths) : 0,
            mother_name: motherName,
            registry: generateRandomString(),
            status: 'done',
            scores: scores,
          };
          // Envio para o endpoint de criação
          response = await fetch('https://escalaemia.com.br/api/save/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${authToken}`,
            },
            body: JSON.stringify(formData),
          });
        }

        if (response.ok) {
          const createdOrUpdatedAssessment = await response.json();
          console.log(
            `Avaliação ${isEditMode ? 'atualizada' : 'criada'} com sucesso:`,
            createdOrUpdatedAssessment
          );
          toast.success(`Avaliação ${isEditMode ? 'atualizada' : 'criada'} com sucesso!`);
          navigate(
            '/dashboard',
            { state: { childName: patientName, motherName: motherName } }
          );
        } else {
          const errorData = await response.json();
          console.error(`Falha ao ${isEditMode ? 'atualizar' : 'criar'} avaliação`, errorData);
          const errorMessage =
            errorData.detail || `Falha ao ${isEditMode ? 'atualizar' : 'criar'} avaliação.`;
          toast.error(errorMessage);
        }
      } catch (err) {
        console.error(`Erro ao ${isEditMode ? 'atualizar' : 'criar'} avaliação:`, err);
        toast.error('Erro ao conectar com o servidor.');
      }

      // Resetar o formulário apenas se não estiver no modo de edição
      if (!isEditMode) {
        setPatientName('');
        setAgeWeeks('');
        setAgeMonths('');
        setIsPremature(false);
        setPrematureAgeWeeks('');
        setStagesSelected([]);
        setMotherName('');
      }

      if (addStagesRef.current) {
        addStagesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const stageTypes = Array.from(new Set(developmentStages.map((stage) => stage.type)));

  const filteredStages = useMemo(() => {
    return selectedType === 'All'
      ? developmentStages
      : developmentStages.filter((stage) => stage.type === selectedType);
  }, [selectedType, developmentStages]);

  const isRequiredFieldsFilled =
    patientName.trim() !== '' &&
    ageWeeks.trim() !== '' &&
    ageMonths.trim() !== '' &&
    !isNaN(Number(ageWeeks)) &&
    Number(ageWeeks) >= 0 &&
    !isNaN(Number(ageMonths)) &&
    Number(ageMonths) >= 0 &&
    (!isPremature ||
      (prematureAgeWeeks.trim() !== '' &&
        !isNaN(Number(prematureAgeWeeks)) &&
        Number(prematureAgeWeeks) >= 0));

  const handleAgeWeeksChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAgeWeeks(value);
    }
  };

  const handleAgeMonthsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAgeMonths(value);
    }
  };

  const handlePrematureAgeWeeksChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPrematureAgeWeeks(value);
    }
  };

  const handleGeneralInfoClick = () => {
    if (generalInfoRef.current) {
      generalInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleStagesSelectedClick = () => {
    if (addStagesRef.current) {
      addStagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderStageItem = (stage: Stage) => {
    const isSelected = stagesSelected.some((s) => s.id === stage.id);

    const handleButtonClick = () => {
      if (isSelected) {
        handleRemoveStage(stage.id);
      } else {
        handleAddStage(stage);
      }
    };

    return (
      <div
        style={{
          textAlign: 'center',
          fontFamily: 'Inter',
          padding: '1em',
          position: 'relative',
          opacity: isSelected ? 0.5 : 1,
          transition: 'opacity 0.3s ease',
          height: '27em',
        }}
        data-cy="stage-card"
      >
        <button
          onClick={handleButtonClick}
          style={{
            backgroundColor: isSelected ? 'red' : 'black',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            fontSize: '1.2em',
          }}
          aria-label={isSelected ? `Remover ${stage.name}` : `Adicionar ${stage.name}`}
        >
          {isSelected ? '−' : '+'}
        </button>
        <h3>{stage.name}</h3>
        {stage.imagePath ? (
          <img src={stage.imagePath} alt={stage.name} style={{ width: '15em', height: '12em' }} />
        ) : (
          <div
            style={{
              width: '15em',
              height: '12em',
              backgroundColor: '#f0f0f0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Imagem indisponível
          </div>
        )}
        <ul style={{ textAlign: 'left', marginTop: '1em' }}>
          {Array.isArray(stage.description) ? (
            stage.description.map((desc, index) => <li key={index}>{desc}</li>)
          ) : (
            <li>{stage.description}</li>
          )}
        </ul>
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'gray',
              opacity: 0.2,
              pointerEvents: 'none',
              borderRadius: '2em',
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <main
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          boxSizing: 'border-box',
          paddingBottom: '1em',
        }}
      >
        <div
          id="user-nav"
          style={{
            display: 'flex',
            minHeight: '14vh',
            alignItems: 'center',
            borderLeft: '1px solid #DEE1E6FF',
            borderBottom: '1px solid #DEE1E6FF',
            padding: '0 1em',
            boxSizing: 'border-box',
            gap: '1em',
          }}
        >
          <div
            style={{
              width: '75%',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5em',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '1.2em',
                fontFamily: 'Inter',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleStagesSelectedClick}
            >
              {isEditMode ? 'Editar Avaliação' : 'Criar Avaliação'}
            </div>
          </div>

          <ButtonComponent
            label="Voltar"
            disabled={false}
            style={{ width: '25%', height: '30px', justifyContent: 'center' }}
            onClick={handleReturnDashboard}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1em',
              position: 'relative',
            }}
            ref={dropdownRef}
          >
            <img
              src={userIcon}
              alt="Usuário"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
              onClick={() => setDropdownOpen(!isDropdownOpen)}
            />
            {isDropdownOpen && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  backgroundColor: 'white',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                  zIndex: 1,
                  borderRadius: '4px',
                  overflow: 'hidden',
                  marginTop: '8px',
                  minWidth: '120px',
                }}
              >
                <button
                  onClick={handleLogout}
                  style={{
                    padding: '10px 20px',
                    width: '100%',
                    textAlign: 'left',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontFamily: 'Epilogue',
                    fontSize: '1em',
                    color: '#171A1FF',
                    outline: 'none',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            padding: '1em',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            boxSizing: 'border-box',
            gap: '2em',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!isSmallScreen && (
              <div style={{ display: 'flex', flexDirection: 'column', minWidth: '15%', height: '15%' }}>
                <div
                  style={{
                    gap: '0.5em',
                    display: 'flex',
                    cursor: 'pointer',
                    fontFamily: 'Inter',
                    alignItems: 'center',
                    fontWeight: 700,
                    color: isRequiredFieldsFilled ? '#565E6CFF' : '#000',
                    backgroundColor: isRequiredFieldsFilled ? '#C8F9FFFF' : 'transparent',
                  }}
                  onClick={handleGeneralInfoClick}
                >
                  <FaCheckCircle
                    color={isRequiredFieldsFilled ? 'green' : '#ccc'}
                    style={{ paddingLeft: '0.5em', paddingTop: '0.5em', paddingBottom: '0.5em' }}
                  />
                  Informações Gerais
                </div>
                <div
                  onClick={handleStagesSelectedClick}
                  style={{
                    gap: '0.5em',
                    display: 'flex',
                    cursor: 'pointer',
                    fontFamily: 'Inter',
                    alignItems: 'center',
                    fontWeight: 700,
                    color: stagesSelected.length > 0 ? '#565E6CFF' : '#000',
                    backgroundColor: stagesSelected.length > 0 ? '#C8F9FFFF' : 'transparent',
                  }}
                >
                  <FaCheckCircle
                    color={stagesSelected.length > 0 ? 'green' : '#ccc'}
                    style={{ paddingLeft: '0.5em', paddingTop: '0.5em', paddingBottom: '0.5em' }}
                  />
                  Selecionar Estágios
                </div>
              </div>
            )}

            <div style={{ minWidth: '65%', height: '100%' }} ref={generalInfoRef}>
              <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}
              >
                <FormInputComponent
                  name="patientName"
                  label="Nome do Paciente"
                  placeholder="Insira o nome do paciente"
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  hasError={!!patientNameError}
                  errorMessage={patientNameError}
                  style={{ width: '90%' }}
                  disabled={isEditMode}
                  dataCy="input-patient-name"
                />
                <FormInputComponent
                  name="motherName"
                  label="Nome da mãe do paciente"
                  placeholder="Insira o nome da mãe do paciente"
                  value={motherName}
                  onChange={(e) => setMotherName(e.target.value)}
                  hasError={!!motherNameError}
                  errorMessage={motherNameError}
                  style={{ width: '90%' }}
                  disabled={isEditMode}
                  dataCy="input-mother-name"
                />
                <FormInputComponent
                  name="ageMonths"
                  label="Idade em Meses"
                  placeholder="Insira a idade em meses"
                  value={ageMonths}
                  onChange={handleAgeMonthsChange}
                  hasError={!!ageMonthsError}
                  errorMessage={ageMonthsError}
                  style={{ width: '90%' }}
                  inputType="numeric"
                  disabled={isEditMode}
                  dataCy="input-patient-age-months"
                />
                <FormInputComponent
                  name="ageWeeks"
                  label="Idade em Semanas"
                  placeholder="Insira a idade em semanas"
                  value={ageWeeks}
                  onChange={handleAgeWeeksChange}
                  hasError={!!ageWeeksError}
                  errorMessage={ageWeeksError}
                  style={{ width: '90%' }}
                  inputType="numeric"
                  disabled={isEditMode}
                  dataCy="input-patient-age-weeks"
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5em',
                    paddingLeft: '0.5em',
                  }}
                >
                  <input
                    type="checkbox"
                    id="isPremature"
                    checked={isPremature}
                    onChange={(e) => setIsPremature(e.target.checked)}
                    disabled={isEditMode}
                    data-cy="checkbox-is-premature"
                  />
                  <label style={{ fontFamily: 'Inter' }} htmlFor="isPremature">
                    Prematuro
                  </label>
                </div>
                {isPremature && (
                  <FormInputComponent
                    name="prematureAgeWeeks"
                    label="Idade do Bebê Prematuro em Semanas"
                    placeholder="Insira a idade prematura em semanas"
                    value={prematureAgeWeeks}
                    onChange={handlePrematureAgeWeeksChange}
                    hasError={!!prematureAgeWeeksError}
                    errorMessage={prematureAgeWeeksError}
                    style={{ width: '90%' }}
                    inputType="numeric"
                    disabled={isEditMode}
                    dataCy="input-premature-age-weeks"
                  />
                )}
              </form>
            </div>
            <div
              id="summary-column"
              style={{
                flex: 1,
                height: '100%',
                padding: '1em',
                borderLeft: '1px solid #DEE1E6FF',
              }}
            >
              <h3 style={{ fontWeight: 'bold', fontFamily: 'Inter' }}>Resumo</h3>
              <div
                style={{
                  fontFamily: 'Inter',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5em',
                }}
              >
                <div>
                  <span style={{ fontWeight: 'bold' }}>Nome do Paciente:</span>{' '}
                  {patientName || 'N/A'}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Nome da Mãe:</span> {motherName || 'N/A'}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Idade:</span>{' '}
                  {isPremature
                    ? `${prematureAgeWeeks || 'N/A'} (Prematura)`
                    : `${ageWeeks || 'N/A'} semanas`}
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Idade em Meses:</span>{' '}
                  {ageMonths || 'N/A'}
                </div>
                <div style={{ overflow: 'scroll', height: '12em' }}>
                  <span style={{ fontWeight: 'bold' }}>Estágios Selecionados:</span>
                  {stagesSelected.length > 0 ? (
                    <div>
                      {Array.from(new Set(stagesSelected.map((stage) => stage.type))).map(
                        (type) => (
                          <div key={type}>
                            <span style={{ fontWeight: 'bold' }}>{`${
                              type[0].toLocaleUpperCase() + type.substring(1)
                            }:`}</span>
                            <ul>
                              {stagesSelected
                                .filter((stage) => stage.type === type)
                                .map((stage) => {
                                  const selectedStage = developmentStages.find(
                                    (s) => s.id === stage.id
                                  );
                                  return selectedStage ? (
                                    <li
                                      key={stage.id}
                                      style={{
                                        display: 'flex',
                                        fontFamily: 'Inter',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <button
                                        onClick={() => handleRemoveStage(stage.id)}
                                        style={{
                                          background: 'none',
                                          border: 'none',
                                          color: 'red',
                                          cursor: 'pointer',
                                          fontSize: '1em',
                                          lineHeight: '1',
                                          marginRight: '0.5em',
                                        }}
                                        aria-label={`Remover ${selectedStage.name}`}
                                      >
                                        &#10005;
                                      </button>
                                      {selectedStage.name}
                                    </li>
                                  ) : null;
                                })}
                            </ul>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <p>Nenhum estágio selecionado</p>
                  )}
                </div>
                <ButtonComponent
                  label={isEditMode ? 'Atualizar Avaliação' : 'Enviar Avaliação'}
                  disabled={!isRequiredFieldsFilled}
                  style={{ width: '200px', height: '40px', alignSelf: 'flex-start' }}
                  type="submit"
                  onClick={handleSubmit}
                  dataCy="button-save-assessment"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              fontFamily: 'Inter',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1em',
              width: '100%',
            }}
            ref={addStagesRef}
          >
            <h2 style={{ textAlign: 'center' }}>Selecione o comportamento observado:</h2>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
              {stageTypes.map((type) => (
                <button
                  key={type}
                  onClick={() => setSelectedType(type)}
                  style={{
                    margin: '0 0.5em',
                    padding: '0.5em 1em',
                    backgroundColor: selectedType === type ? '#007bff' : '#6c757d',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  data-cy="button-stage-type"
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>

            <CarouselComponent<Stage>
              data={filteredStages}
              show={3}
              renderItem={renderStageItem}
              infiniteLoop={false}
              searchPlaceholder="Pesquisar estágios..."
            />
          </div>
        </div>
      </main>
      <Footer
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#f7fafc',
          minHeight: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      />
    </div>
  );
};

export default CreateAssessmentPage;
