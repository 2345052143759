type AllowedFormsInterfaces = 'RegisterForm' | 'LoginForm';

const interfaceFields: { [key in AllowedFormsInterfaces]: string[] } = {
    RegisterForm: [
        'username',
        'email',
        'password',
        'confirm_password',
        'full_name',
        'profession',
        'contact_number',
        'workplace',
    ],
    LoginForm: ['username', 'password'],
};

class ParseError extends Error {
    public errorData: any;
    public interfaceName: AllowedFormsInterfaces;

    constructor(errorData: any, interfaceName: AllowedFormsInterfaces) {
        super();
        this.errorData = errorData;
        this.interfaceName = interfaceName;

        if (!this.isInterfaceAllowed(interfaceName)) {
            throw new Error(`Interface "${interfaceName}" não é permitida.`);
        }

        this.checkFieldValidity(interfaceName);
    }

    private isInterfaceAllowed(interfaceName: string): boolean {
        return Object.keys(interfaceFields).includes(interfaceName);
    }

    private checkFieldValidity(interfaceName: AllowedFormsInterfaces) {
        const allowedFields = interfaceFields[interfaceName];
        Object.keys(this.errorData).forEach((key) => {
            if (!allowedFields.includes(key)) {
                throw new Error(`O campo "${key}" não existe na interface "${interfaceName}".`);
            }
        });
    }

    handleError() {
        switch (this.interfaceName) {
            case 'RegisterForm':
                return this.handleRegisterError();
            case 'LoginForm':
                return this.handleLoginError();
            default:
                return this.handleGeneralError();
        }
    }

    private handleRegisterError() {
        const fieldErrors: { [key: string]: string } = {};

        if (this.errorData.username) {
            fieldErrors['username'] = `Nome do usuario já está em uso. Se já possui login, tente logar ou cadastre-se com outro usuário.`;
        }

        if (this.errorData.email) {
            fieldErrors['email'] = `Email já está em uso. Se já possui login, tente logar ou cadastre-se com outro usuário.`;
        }


        return fieldErrors;
    }

    private handleLoginError() {
        const fieldErrors: { [key: string]: string } = {};

        if (this.errorData.auth) {
            fieldErrors['auth'] = 'Credenciais inválidas. Verifique seu nome de usuário e senha.';
        }

        return fieldErrors;
    }

    private handleGeneralError() {
        const fieldErrors: { [key: string]: string } = {};

        if (this.errorData.general) {
            fieldErrors['general'] = this.errorData.general || 'Erro desconhecido. Por favor, tente novamente.';
        }

        return fieldErrors;
    }
}

export { ParseError, AllowedFormsInterfaces };
